@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro:400,400i,700,700i|Source+Sans+Pro:400,400i,600,600i,700,700i,900,900i&display=swap);
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: initial;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: initial;
}
a:active,
a:focus,
a:hover {
  outline: none;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ffc800;
  color: #151515;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: initial;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
hr {
  box-sizing: initial;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: initial;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: 700;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
* {
  outline: none;
}
*,
:after,
:before,
body {
  box-sizing: border-box;
}
body {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #151515;
  text-decoration: none;
  word-wrap: normal;
  white-space: normal;
  outline: none;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #005975;
  cursor: pointer;
}
a,
a:focus,
a:hover {
  text-decoration: underline;
}
a:focus,
a:hover {
  color: #002938;
}
img {
  vertical-align: middle;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
h1 {
  font-size: 39.0625px;
  line-height: 1.15;
  margin-top: 20px;
  margin-bottom: 12.8px;
}
h1:first-child {
  margin-top: 0;
}
h1:last-child {
  margin-bottom: 0;
}
h2 {
  margin-bottom: 8px;
  font-size: 25px;
  line-height: 1.2;
}
h2:first-child {
  margin-top: 0;
}
h2:last-child {
  margin-bottom: 0;
}
h3 {
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 1.2;
}
h3:first-child {
  margin-top: 0;
}
h3:last-child {
  margin-bottom: 0;
}
h4 {
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 1.1;
}
h4:first-child {
  margin-top: 0;
}
h4:last-child {
  margin-bottom: 0;
}
p {
  margin: 0;
}
p + p {
  margin-top: 6px;
}
p + .btn {
  margin-top: 12px;
}
ol,
ul {
  margin: 8px;
  padding-left: 16px;
}
h2 + h3 {
  margin-top: 14px;
}
p + h3 {
  margin-top: 20px;
}
p + .form__group {
  margin-top: 16px;
}
.type--no-break {
  white-space: nowrap;
}
@font-face {
  font-family: kentico-icons;
  font-style: normal;
  font-weight: 400;
  src: url(kentico-icons-v3.0.1.woff2) format("woff2");
}
.headline-large {
  font-size: 24px;
  line-height: 32px;
}
.headline-large,
.headline-medium {
  font-family: GT Walsheim Pro, sans-serif;
  font-weight: 700;
}
.headline-medium {
  font-size: 16px;
  line-height: 24px;
}
.headline-small {
  font-weight: 700;
}
.headline-small,
.subheadline {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 14px;
  line-height: 16px;
}
.subheadline {
  font-weight: 400;
}
.subheadline-large {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.title-large {
  font-size: 14px;
}
.title-large,
.title-medium {
  font-family: GT Walsheim Pro, sans-serif;
  font-weight: 500;
  line-height: 16px;
}
.title-medium {
  font-size: 12px;
}
.title-small {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
}
.body-large {
  font-size: 16px;
  line-height: 24px;
}
.body-large,
.body-medium {
  font-family: Inter, sans-serif;
  font-weight: 400;
}
.body-medium {
  font-size: 14px;
  line-height: 20px;
}
.body-small {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.body-large-emphasis {
  font-size: 16px;
  line-height: 24px;
}
.body-large-emphasis,
.body-medium-emphasis {
  font-family: Inter, sans-serif;
  font-weight: 500;
}
.body-medium-emphasis {
  font-size: 14px;
  line-height: 20px;
}
.body-small-emphasis {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.label-large {
  font-size: 14px;
}
.label-large,
.label-medium {
  font-family: GT Walsheim Pro, sans-serif;
  font-weight: 400;
  line-height: 16px;
}
.label-medium {
  font-size: 12px;
}
.label-small {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
}
.label-large-emphasis {
  font-size: 14px;
}
.label-large-emphasis,
.label-medium-emphasis {
  font-family: GT Walsheim Pro, sans-serif;
  font-weight: 500;
  line-height: 16px;
}
.label-medium-emphasis {
  font-size: 12px;
}
.label-small-emphasis {
  font-weight: 500;
}
.caption,
.label-small-emphasis {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 11px;
  line-height: 11px;
}
.caption {
  font-weight: 400;
}
.action-large {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1ch;
  text-transform: uppercase;
}
.action-medium {
  font-weight: 700;
}
.action-medium,
.action-small {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1ch;
  text-transform: uppercase;
}
.action-small {
  font-weight: 500;
}
.ui-paragraph {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
[class*=" icon-"],
[class^="icon-"] {
  display: inline-block;
  font-family: kentico-icons;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
}
.icon--xs {
  font-size: 12px;
}
[class^="icon-"]:before {
  content: "\e619";
}
.switch-icon-select:hover {
  content: "\e676";
}
.icon-a-lowercase:before {
  content: "\e74f";
}
.icon-accordion:before {
  content: "\e704";
}
.icon-android:before {
  content: "\f17b";
}
.icon-apple:before {
  content: "\f179";
}
.icon-arrow-crooked-left:before {
  content: "\e6e0";
}
.icon-arrow-crooked-right:before {
  content: "\e6e1";
}
.icon-arrow-curved-left:before {
  content: "\e908";
}
.icon-arrow-curved-right:before {
  content: "\e909";
}
.icon-arrow-double-left:before {
  content: "\e6df";
}
.icon-arrow-double-right:before {
  content: "\e6de";
}
.icon-arrow-down:before {
  content: "\e682";
}
.icon-arrow-down-circle:before {
  content: "\e6ae";
}
.icon-arrow-down-line:before {
  content: "\e6dd";
}
.icon-arrow-enter-square:before {
  content: "\e801";
}
.icon-arrow-leave-square:before {
  content: "\e800";
}
.icon-arrow-left:before {
  content: "\e6dc";
}
.icon-arrow-left-circle:before {
  content: "\e6af";
}
.icon-arrow-left-rect:before {
  content: "\e6db";
}
.icon-arrow-right:before {
  content: "\e6da";
}
.icon-arrow-right-circle:before {
  content: "\e6b1";
}
.icon-arrow-right-rect:before {
  content: "\e6d9";
}
.icon-arrow-right-top-square:before {
  content: "\e6d8";
}
.icon-arrow-u-left:before {
  content: "\e677";
}
.icon-arrow-u-right:before {
  content: "\e703";
}
.icon-arrow-up:before {
  content: "\e64c";
}
.icon-arrow-up-circle:before {
  content: "\e6bf";
}
.icon-arrow-up-line:before {
  content: "\e6d3";
}
.icon-arrows:before {
  content: "\e6d7";
}
.icon-arrows-crooked:before {
  content: "\e6f4";
}
.icon-arrows-h:before {
  content: "\e6d5";
}
.icon-arrows-v:before {
  content: "\e6d4";
}
.icon-b:before {
  content: "\e746";
}
.icon-badge:before {
  content: "\e6d2";
}
.icon-ban-sign:before {
  content: "\e6d1";
}
.icon-bell:before {
  content: "\e91f";
}
.icon-bezier-scheme:before {
  content: "\e717";
}
.icon-bin:before {
  content: "\e6d0";
}
.icon-binder:before {
  content: "\e921";
}
.icon-book-opened:before {
  content: "\e6cf";
}
.icon-box:before {
  content: "\e6ce";
}
.icon-box-cart:before {
  content: "\e6cd";
}
.icon-box-clock:before {
  content: "\e928";
}
.icon-box-disc:before {
  content: "\e61c";
}
.icon-boxes:before {
  content: "\e6cc";
}
.icon-braces:before {
  content: "\e6cb";
}
.icon-braces-octothorpe:before {
  content: "\e740";
}
.icon-brand-bing:before {
  content: "\e6ca";
}
.icon-brand-facebook:before {
  content: "\e6c9";
}
.icon-brand-google:before {
  content: "\e6c8";
}
.icon-brand-google-plus:before {
  content: "\e6e6";
}
.icon-brand-instagram:before {
  content: "\e903";
}
.icon-brand-linkedin:before {
  content: "\e6e5";
}
.icon-brand-mstranslator:before {
  content: "\e90c";
}
.icon-brand-open-id:before {
  content: "\e6e4";
}
.icon-brand-pinterest:before {
  content: "\e6e3";
}
.icon-brand-sharepoint:before {
  content: "\e707";
}
.icon-brand-twitter:before {
  content: "\e6c7";
}
.icon-brand-youtube:before {
  content: "\e659";
}
.icon-breadcrumb:before {
  content: "\e6f9";
}
.icon-briefcase:before {
  content: "\e6c6";
}
.icon-broom:before {
  content: "\e6c5";
}
.icon-brush:before {
  content: "\e6c4";
}
.icon-brush-slashed:before {
  content: "\e914";
}
.icon-bubble:before {
  content: "\e6f3";
}
.icon-bubble-censored:before {
  content: "\e6c2";
}
.icon-bubble-check:before {
  content: "\e919";
}
.icon-bubble-hide:before {
  content: "\e91c";
}
.icon-bubble-inverted:before {
  content: "\e6c3";
}
.icon-bubble-pencil:before {
  content: "\e911";
}
.icon-bubble-show:before {
  content: "\e91d";
}
.icon-bubble-times:before {
  content: "\e6f2";
}
.icon-bubble-with-lines:before {
  content: "\e922";
}
.icon-bubbles:before {
  content: "\e6c1";
}
.icon-bucket-shovel:before {
  content: "\e6be";
}
.icon-bug:before {
  content: "\e6bd";
}
.icon-building:before {
  content: "\e6bc";
}
.icon-building-block:before {
  content: "\e6bb";
}
.icon-bullseye:before {
  content: "\e6ba";
}
.icon-c-sharp:before {
  content: "\e718";
}
.icon-calendar:before {
  content: "\e6b9";
}
.icon-calendar-number:before {
  content: "\e6b8";
}
.icon-camera:before {
  content: "\e6b7";
}
.icon-cancel:before {
  content: "\e804";
}
.icon-caret-down:before {
  content: "\e6b6";
}
.icon-caret-left:before {
  content: "\e6b5";
}
.icon-caret-left-aligned-right:before {
  content: "\e73b";
}
.icon-caret-right:before {
  content: "\e6b4";
}
.icon-caret-right-aligned-left:before {
  content: "\e720";
}
.icon-caret-right-down:before {
  content: "\e6b3";
}
.icon-caret-up:before {
  content: "\e6b2";
}
.icon-carousel:before {
  content: "\e6f1";
}
.icon-cb-check:before {
  content: "\e902";
}
.icon-cb-check-disabled:before {
  content: "\e6f0";
}
.icon-cb-check-inverted:before {
  content: "\e923";
}
.icon-cb-check-minus:before {
  content: "\e91a";
}
.icon-cb-check-preview:before {
  content: "\e702";
}
.icon-cb-check-sign:before {
  content: "\e6b0";
}
.icon-cb-uncheck:before {
  content: "\e6ad";
}
.icon-chain:before {
  content: "\e67f";
}
.icon-chain-broken:before {
  content: "\e92e";
}
.icon-chain-slash:before {
  content: "\e691";
}
.icon-check:before {
  content: "\e676";
}
.icon-check-circle:before {
  content: "\e675";
}
.icon-check-circle-inverted:before {
  content: "\e92d";
}
.icon-checklist:before {
  content: "\e61b";
}
.icon-chevron-double-down:before {
  content: "\e673";
}
.icon-chevron-double-left:before {
  content: "\e672";
}
.icon-chevron-double-right:before {
  content: "\e671";
}
.icon-chevron-double-up:before {
  content: "\e670";
}
.icon-chevron-down:before {
  content: "\e66f";
}
.icon-chevron-down-circle:before {
  content: "\e66e";
}
.icon-chevron-down-line:before {
  content: "\e6c0";
}
.icon-chevron-down-square:before {
  content: "\e66d";
}
.icon-chevron-left:before {
  content: "\e66c";
}
.icon-chevron-left-circle:before {
  content: "\e66b";
}
.icon-chevron-left-line:before {
  content: "\e6d6";
}
.icon-chevron-left-square:before {
  content: "\e66a";
}
.icon-chevron-right:before {
  content: "\e669";
}
.icon-chevron-right-circle:before {
  content: "\e67e";
}
.icon-chevron-right-line:before {
  content: "\e6e2";
}
.icon-chevron-right-square:before {
  content: "\e668";
}
.icon-chevron-up:before {
  content: "\e667";
}
.icon-chevron-up-circle:before {
  content: "\e666";
}
.icon-chevron-up-line:before {
  content: "\e6ee";
}
.icon-chevron-up-square:before {
  content: "\e665";
}
.icon-choice-multi-scheme:before {
  content: "\e757";
}
.icon-choice-single-scheme:before {
  content: "\e755";
}
.icon-choice-user-scheme:before {
  content: "\e753";
}
.icon-chrome:before {
  content: "\f268";
}
.icon-circle:before {
  content: "\f10c";
}
.icon-circle-dashed:before {
  content: "\e924";
}
.icon-circle-inverted:before {
  content: "\e6ac";
}
.icon-circle-slashed:before {
  content: "\e751";
}
.icon-circle-square:before {
  content: "\e6ab";
}
.icon-clapperboard:before {
  content: "\e636";
}
.icon-clipboard-checklist:before {
  content: "\e6aa";
}
.icon-clipboard-list:before {
  content: "\e6a9";
}
.icon-clock:before {
  content: "\e6a8";
}
.icon-cloud:before {
  content: "\e701";
}
.icon-cogwheel:before {
  content: "\e6a7";
}
.icon-cogwheel-square:before {
  content: "\e6a5";
}
.icon-cogwheels:before {
  content: "\e6a6";
}
.icon-collapse:before {
  content: "\e745";
}
.icon-collapse-scheme:before {
  content: "\e700";
}
.icon-compass:before {
  content: "\e6a4";
}
.icon-convert:before {
  content: "\e90d";
}
.icon-cookie:before {
  content: "\e6f7";
}
.icon-crosshair-f:before {
  content: "\e71f";
}
.icon-crosshair-o:before {
  content: "\e71b";
}
.icon-cup:before {
  content: "\e6a2";
}
.icon-custom-element:before {
  content: "\e91b";
}
.icon-database:before {
  content: "\e6a0";
}
.icon-desktop:before {
  content: "\f108";
}
.icon-dialog-window:before {
  content: "\e6ff";
}
.icon-dialog-window-cogwheel:before {
  content: "\e71a";
}
.icon-diamond:before {
  content: "\e756";
}
.icon-doc:before {
  content: "\e69c";
}
.icon-doc-arrows:before {
  content: "\e6fe";
}
.icon-doc-ban-sign:before {
  content: "\e6ef";
}
.icon-doc-copy:before {
  content: "\e69e";
}
.icon-doc-inverted:before {
  content: "\e69f";
}
.icon-doc-move:before {
  content: "\e69d";
}
.icon-doc-paste:before {
  content: "\e69a";
}
.icon-doc-plus:before {
  content: "\e918";
}
.icon-doc-torn:before {
  content: "\e750";
}
.icon-doc-user:before {
  content: "\e714";
}
.icon-dollar-sign:before {
  content: "\e6ed";
}
.icon-dots-vertical:before {
  content: "\e75d";
}
.icon-down-dir:before {
  content: "\e805";
}
.icon-drawers:before {
  content: "\e699";
}
.icon-e-book:before {
  content: "\e697";
}
.icon-earth:before {
  content: "\e698";
}
.icon-edge:before {
  content: "\f282";
}
.icon-edit:before {
  content: "\e696";
}
.icon-ekg-line:before {
  content: "\e695";
}
.icon-ellipsis:before {
  content: "\e694";
}
.icon-engage-users:before {
  content: "\e920";
}
.icon-exclamation-triangle:before {
  content: "\e693";
}
.icon-exclamation-triangle-inverted:before {
  content: "\e925";
}
.icon-expand:before {
  content: "\e744";
}
.icon-eye:before {
  content: "\e692";
}
.icon-eye-slash:before {
  content: "\e75c";
}
.icon-f:before {
  content: "\e74e";
}
.icon-factory:before {
  content: "\e690";
}
.icon-file:before {
  content: "\e719";
}
.icon-file-pdf:before {
  content: "\e6a3";
}
.icon-filter-1:before {
  content: "\f0b0";
}
.icon-firefox:before {
  content: "\f269";
}
.icon-flag:before {
  content: "\e68f";
}
.icon-folder:before {
  content: "\e68b";
}
.icon-folder-belt:before {
  content: "\e715";
}
.icon-folder-clock:before {
  content: "\e68c";
}
.icon-folder-inverted:before {
  content: "\e68d";
}
.icon-folder-opened:before {
  content: "\e68a";
}
.icon-form:before {
  content: "\e689";
}
.icon-funnel:before {
  content: "\e687";
}
.icon-gauge:before {
  content: "\e686";
}
.icon-gift:before {
  content: "\e915";
}
.icon-graduate-cap:before {
  content: "\e713";
}
.icon-graph:before {
  content: "\e684";
}
.icon-h-1:before {
  content: "\e92f";
}
.icon-h-2:before {
  content: "\e930";
}
.icon-h-3:before {
  content: "\e931";
}
.icon-h-4:before {
  content: "\e932";
}
.icon-half-arrows-right-left:before {
  content: "\e683";
}
.icon-hat-moustache:before {
  content: "\e75a";
}
.icon-heart:before {
  content: "\e91e";
}
.icon-heartshake:before {
  content: "\e681";
}
.icon-highlighter:before {
  content: "\e6f6";
}
.icon-home:before {
  content: "\e680";
}
.icon-i:before {
  content: "\e743";
}
.icon-i-circle:before {
  content: "\e664";
}
.icon-id-card:before {
  content: "\e663";
}
.icon-id-cards:before {
  content: "\e662";
}
.icon-indent:before {
  content: "\e742";
}
.icon-integration-scheme:before {
  content: "\e661";
}
.icon-intercom:before {
  content: "\e916";
}
.icon-internet-explorer:before {
  content: "\f26b";
}
.icon-invoice:before {
  content: "\e660";
}
.icon-kentico:before {
  content: "\e65f";
}
.icon-key:before {
  content: "\e65e";
}
.icon-l-article-map:before {
  content: "\e721";
}
.icon-l-calendar-number-article:before {
  content: "\e722";
}
.icon-l-cols-2:before {
  content: "\e712";
}
.icon-l-cols-20-60-20:before {
  content: "\e6a1";
}
.icon-l-cols-20-80:before {
  content: "\e70f";
}
.icon-l-cols-25-50-25:before {
  content: "\e688";
}
.icon-l-cols-3:before {
  content: "\e711";
}
.icon-l-cols-30-70:before {
  content: "\e641";
}
.icon-l-cols-4:before {
  content: "\e710";
}
.icon-l-cols-70-30:before {
  content: "\e637";
}
.icon-l-cols-80-20:before {
  content: "\e70e";
}
.icon-l-forms-2:before {
  content: "\e723";
}
.icon-l-grid-2-2:before {
  content: "\e628";
}
.icon-l-grid-3-2:before {
  content: "\e611";
}
.icon-l-header-cols-2-footer:before {
  content: "\e724";
}
.icon-l-header-cols-3-footer:before {
  content: "\e70d";
}
.icon-l-header-list-img:before {
  content: "\e725";
}
.icon-l-header-menu-text:before {
  content: "\e726";
}
.icon-l-header-text:before {
  content: "\e727";
}
.icon-l-img-2-cols-3:before {
  content: "\e72b";
}
.icon-l-img-3-cols-3:before {
  content: "\e72a";
}
.icon-l-lightbox:before {
  content: "\e729";
}
.icon-l-list-article:before {
  content: "\e728";
}
.icon-l-list-article-col:before {
  content: "\e739";
}
.icon-l-list-img-article:before {
  content: "\e738";
}
.icon-l-list-title:before {
  content: "\e737";
}
.icon-l-logotype-menu-col-footer:before {
  content: "\e736";
}
.icon-l-logotype-menu-h-col:before {
  content: "\e70c";
}
.icon-l-logotype-menu-v-col:before {
  content: "\e6fc";
}
.icon-l-menu-cols-2:before {
  content: "\e735";
}
.icon-l-menu-cols-3:before {
  content: "\e6e8";
}
.icon-l-menu-list:before {
  content: "\e734";
}
.icon-l-menu-list-img:before {
  content: "\e733";
}
.icon-l-menu-list-img-col:before {
  content: "\e732";
}
.icon-l-menu-text:before {
  content: "\e731";
}
.icon-l-menu-text-col:before {
  content: "\e69b";
}
.icon-l-menu-text-col-bottom:before {
  content: "\e730";
}
.icon-l-rows-2:before {
  content: "\e72f";
}
.icon-l-rows-3:before {
  content: "\e72e";
}
.icon-l-rows-4:before {
  content: "\e72d";
}
.icon-l-text:before {
  content: "\e72c";
}
.icon-l-text-col:before {
  content: "\e685";
}
.icon-laptop:before {
  content: "\e65d";
}
.icon-layout:before {
  content: "\e65c";
}
.icon-layouts:before {
  content: "\e65b";
}
.icon-life-belt:before {
  content: "\e65a";
}
.icon-light-bulb:before {
  content: "\e67d";
}
.icon-linux:before {
  content: "\f17c";
}
.icon-list:before {
  content: "\e6f8";
}
.icon-list-bullets:before {
  content: "\e754";
}
.icon-list-numbers:before {
  content: "\e75b";
}
.icon-lock:before {
  content: "\e658";
}
.icon-lock-unlocked:before {
  content: "\e6ec";
}
.icon-loop:before {
  content: "\e600";
}
.icon-magnifier:before {
  content: "\e657";
}
.icon-magnifier-minus:before {
  content: "\e656";
}
.icon-magnifier-plus:before {
  content: "\e655";
}
.icon-map:before {
  content: "\e654";
}
.icon-map-marker:before {
  content: "\e653";
}
.icon-market:before {
  content: "\e68e";
}
.icon-mask:before {
  content: "\e652";
}
.icon-media-player:before {
  content: "\e651";
}
.icon-menu:before {
  content: "\e650";
}
.icon-merge:before {
  content: "\e709";
}
.icon-message:before {
  content: "\e64f";
}
.icon-messages:before {
  content: "\e64e";
}
.icon-microphone:before {
  content: "\e64d";
}
.icon-minus:before {
  content: "\e73f";
}
.icon-minus-circle:before {
  content: "\e64b";
}
.icon-minus-square:before {
  content: "\e67c";
}
.icon-mobile:before {
  content: "\f10b";
}
.icon-modal-close:before {
  content: "\e64a";
}
.icon-modal-maximize:before {
  content: "\e649";
}
.icon-modal-minimize:before {
  content: "\e648";
}
.icon-modal-question:before {
  content: "\e647";
}
.icon-money-bill:before {
  content: "\e708";
}
.icon-monitor:before {
  content: "\e646";
}
.icon-monitor-broken:before {
  content: "\e70b";
}
.icon-monitor-smartphone:before {
  content: "\e645";
}
.icon-mug:before {
  content: "\e644";
}
.icon-newspaper:before {
  content: "\e643";
}
.icon-note:before {
  content: "\e71c";
}
.icon-notebook:before {
  content: "\e642";
}
.icon-octothorpe:before {
  content: "\e904";
}
.icon-one:before {
  content: "\e752";
}
.icon-opera:before {
  content: "\f26a";
}
.icon-organisational-scheme:before {
  content: "\e640";
}
.icon-outdent:before {
  content: "\e741";
}
.icon-palette:before {
  content: "\e63f";
}
.icon-pants:before {
  content: "\e63e";
}
.icon-paper-plane:before {
  content: "\e917";
}
.icon-paperclip:before {
  content: "\e63d";
}
.icon-paragraph:before {
  content: "\e905";
}
.icon-paragraph-center:before {
  content: "\e90a";
}
.icon-paragraph-short:before {
  content: "\e906";
}
.icon-parent-child-scheme:before {
  content: "\e74d";
}
.icon-parent-child-scheme-inverted:before {
  content: "\e74c";
}
.icon-parent-children-scheme-2:before {
  content: "\e63c";
}
.icon-parent-children-scheme-3:before {
  content: "\e67b";
}
.icon-pause:before {
  content: "\e63b";
}
.icon-pc:before {
  content: "\e63a";
}
.icon-pda:before {
  content: "\e639";
}
.icon-percent-sign:before {
  content: "\e638";
}
.icon-perfume:before {
  content: "\e635";
}
.icon-permission-list:before {
  content: "\e634";
}
.icon-personalisation:before {
  content: "\e900";
}
.icon-personalisation-variants:before {
  content: "\e901";
}
.icon-picture:before {
  content: "\e633";
}
.icon-pictures:before {
  content: "\e632";
}
.icon-piechart:before {
  content: "\e631";
}
.icon-piechart-lines:before {
  content: "\e71d";
}
.icon-pilcrow:before {
  content: "\e933";
}
.icon-pin:before {
  content: "\e705";
}
.icon-pin-inverted:before {
  content: "\e71e";
}
.icon-placeholder:before {
  content: "\e630";
}
.icon-plus:before {
  content: "\e62f";
}
.icon-plus-circle:before {
  content: "\e62e";
}
.icon-plus-square:before {
  content: "\e62d";
}
.icon-printer:before {
  content: "\e67a";
}
.icon-process-scheme:before {
  content: "\e62c";
}
.icon-project-scheme:before {
  content: "\e62b";
}
.icon-puzzle:before {
  content: "\e62a";
}
.icon-puzzle-plus:before {
  content: "\e90e";
}
.icon-qr-code:before {
  content: "\e6eb";
}
.icon-question-circle:before {
  content: "\e629";
}
.icon-rb-check:before {
  content: "\e907";
}
.icon-rb-check-sign:before {
  content: "\e627";
}
.icon-rb-uncheck:before {
  content: "\e626";
}
.icon-recaptcha:before {
  content: "\e910";
}
.icon-rectangle-a:before {
  content: "\e623";
}
.icon-rectangle-a-inverted:before {
  content: "\e61e";
}
.icon-rectangle-h:before {
  content: "\e758";
}
.icon-rectangle-lines:before {
  content: "\e6fd";
}
.icon-rectangle-paragraph:before {
  content: "\e625";
}
.icon-rectangle-v:before {
  content: "\e759";
}
.icon-restriction-list:before {
  content: "\e6ea";
}
.icon-ribbon:before {
  content: "\e624";
}
.icon-right-double-quotation-mark:before {
  content: "\e73d";
}
.icon-rotate-double-right:before {
  content: "\e622";
}
.icon-rotate-left:before {
  content: "\e621";
}
.icon-rotate-right:before {
  content: "\e620";
}
.icon-rss:before {
  content: "\e6e9";
}
.icon-s:before {
  content: "\e748";
}
.icon-safari:before {
  content: "\f267";
}
.icon-scheme-circles-triangle:before {
  content: "\e73e";
}
.icon-scheme-connected-circles:before {
  content: "\e802";
}
.icon-scheme-path-circles:before {
  content: "\e803";
}
.icon-scheme-path-circles-flipped:before {
  content: "\e90f";
}
.icon-scissors:before {
  content: "\e61f";
}
.icon-separate:before {
  content: "\e70a";
}
.icon-share-apple:before {
  content: "\e934";
}
.icon-share-google:before {
  content: "\e935";
}
.icon-shoe-women:before {
  content: "\e679";
}
.icon-shopping-cart:before {
  content: "\e6f5";
}
.icon-small-dot-centered:before {
  content: "\e926";
}
.icon-smartphone:before {
  content: "\e61d";
}
.icon-spinner:before {
  content: "\e61a";
}
.icon-square:before {
  content: "\e619";
}
.icon-square-dashed:before {
  content: "\e618";
}
.icon-square-dashed-line:before {
  content: "\e617";
}
.icon-staging-scheme:before {
  content: "\e616";
}
.icon-star-empty:before {
  content: "\e615";
}
.icon-star-full:before {
  content: "\e614";
}
.icon-star-semi:before {
  content: "\e613";
}
.icon-subscript:before {
  content: "\e913";
}
.icon-superscript:before {
  content: "\e912";
}
.icon-t:before {
  content: "\e74b";
}
.icon-t-f:before {
  content: "\e74a";
}
.icon-t-shirt:before {
  content: "\e608";
}
.icon-t-with-cross:before {
  content: "\e929";
}
.icon-tab:before {
  content: "\e6fb";
}
.icon-tab-vertical:before {
  content: "\e73c";
}
.icon-table:before {
  content: "\e612";
}
.icon-tablet:before {
  content: "\e610";
}
.icon-tag:before {
  content: "\e60f";
}
.icon-tags:before {
  content: "\e678";
}
.icon-three-dots-vertical:before {
  content: "\e927";
}
.icon-times:before {
  content: "\e60e";
}
.icon-times-circle:before {
  content: "\e60d";
}
.icon-translate:before {
  content: "\e60c";
}
.icon-tree-structure:before {
  content: "\e73a";
}
.icon-triangle-right:before {
  content: "\e60b";
}
.icon-trophy:before {
  content: "\e60a";
}
.icon-truck:before {
  content: "\e609";
}
.icon-two-rectangles-h:before {
  content: "\e607";
}
.icon-two-rectangles-stacked:before {
  content: "\e90b";
}
.icon-two-rectangles-stacked-times:before {
  content: "\e92a";
}
.icon-two-rectangles-v:before {
  content: "\e606";
}
.icon-two-squares-line:before {
  content: "\e706";
}
.icon-two-squares-with-lines:before {
  content: "\e92b";
}
.icon-u:before {
  content: "\e747";
}
.icon-up-dir:before {
  content: "\e92c";
}
.icon-user:before {
  content: "\e605";
}
.icon-user-checkbox:before {
  content: "\e603";
}
.icon-user-frame:before {
  content: "\e604";
}
.icon-users:before {
  content: "\e602";
}
.icon-vb:before {
  content: "\e716";
}
.icon-watch:before {
  content: "\e601";
}
.icon-windows:before {
  content: "\f17a";
}
.icon-wizard-stick:before {
  content: "\e6fa";
}
.icon-x:before {
  content: "\e749";
}
.icon-xml-tag:before {
  content: "\e6e7";
}
@keyframes pulse-500 {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
.pulse-500 {
  animation-direction: alternate;
  animation-delay: 0s;
  animation-duration: 1.75s;
  animation-iteration-count: infinite;
  animation-name: pulse-500;
  animation-timing-function: ease-in;
}
@keyframes pulse-700 {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0.7;
  }
}
.pulse-700 {
  animation-direction: alternate;
  animation-delay: 0s;
  animation-duration: 1.75s;
  animation-iteration-count: infinite;
  animation-name: pulse-700;
  animation-timing-function: ease-in;
}
@keyframes spin-500 {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.spin-500 {
  animation-direction: normal;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: spin-500;
  animation-timing-function: linear;
}
.slide-in-50 {
  animation: slide-in-50 0.5s forwards;
}
.slide-out-50 {
  animation: slide-out-50 0.5s forwards;
}
@keyframes slide-in-50 {
  0% {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-out-50 {
  0% {
    max-height: 50px;
    transform: translateY(0);
  }
  to {
    max-height: 0;
    transform: translateY(-100%);
  }
}
.fade-leave {
  animation: fade-in 0.3s reverse;
  animation-fill-mode: forwards;
}
.fade-enter {
  animation: fade-in 0.3s normal;
  animation-fill-mode: forwards;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.action-scaling-animation {
  animation: action-scaling-animation 0.2s ease;
}
@keyframes action-scaling-animation {
  0% {
    transform: scale(0.01);
  }
  to {
    transform: scale(1);
  }
}
@keyframes skelet-loader {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}
@keyframes progressIndicatorMovement {
  0% {
    left: -22%;
  }
  to {
    left: 92%;
  }
}
.animation-nudge {
  float: left;
  animation-name: shuffle;
  animation-duration: 6s;
  animation-delay: 4s;
  animation-iteration-count: 2;
  animation-timing-function: linear;
  transform-origin: center center;
}
@keyframes shuffle {
  0% {
    transform: translate(0) scale(1);
  }
  2% {
    transform: translate(2px, -3px) scale(1) rotate(5deg);
  }
  4% {
    transform: translate(0) scale(1) rotate(0deg);
  }
  6% {
    transform: translate(-2px, -3px) scale(1) rotate(-5deg);
  }
  8% {
    transform: translate(0) scale(1);
  }
  to {
    transform: translate(0);
  }
}
.btn-wrapper {
  display: inline-block;
}
.btn-wrapper--block {
  display: block;
  width: 100%;
}
.btn-wrapper + .btn-wrapper {
  margin-left: 8px;
}
.btn {
  height: 40px;
  padding: 0 24px;
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.1ch;
  position: relative;
  display: flex;
  margin-bottom: 0;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  border-width: 0;
  border-radius: 5000px;
  outline: none;
  touch-action: manipulation;
  transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1) 50ms, border-width 0ms,
    padding 0ms;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  justify-content: center;
}
.btn.focus,
.btn:focus,
.btn:hover {
  text-decoration: none;
  outline: none;
}
.btn.active,
.btn:active {
  outline: none;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
}
.btn--primary {
  background: #db3c00;
  box-shadow: 0 8px 14px 2px rgba(244, 92, 35, 0.14),
    0 6px 20px 5px rgba(244, 92, 35, 0.12),
    0 8px 10px -5px rgba(244, 92, 35, 0.2);
  color: #fff;
}
.btn--primary:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff,
    0 8px 14px 2px rgba(244, 92, 35, 0.14),
    0 6px 20px 5px rgba(244, 92, 35, 0.12),
    0 8px 10px -5px rgba(244, 92, 35, 0.2);
}
.js-focus-visible .btn--primary:focus:focus:not(.focus-visible) {
  box-shadow: 0 8px 14px 2px rgba(244, 92, 35, 0.14),
    0 6px 20px 5px rgba(244, 92, 35, 0.12),
    0 8px 10px -5px rgba(244, 92, 35, 0.2);
}
.btn--primary.active,
.btn--primary:active,
.btn--primary:hover {
  background: #953000;
}
.btn--primary.disabled,
.btn--primary.disabled:active,
.btn--primary.disabled:focus,
.btn--primary.disabled:hover,
.btn--primary.disabled[disabled],
.btn--primary[disabled],
.btn--primary[disabled]:active,
.btn--primary[disabled]:focus,
.btn--primary[disabled]:hover,
.btn--primary[disabled][disabled],
fieldset[disabled] .btn--primary,
fieldset[disabled] .btn--primary:active,
fieldset[disabled] .btn--primary:focus,
fieldset[disabled] .btn--primary:hover,
fieldset[disabled] .btn--primary[disabled] {
  color: #8c8c8c;
  background: #dfdfdf;
  box-shadow: none;
}
.btn--secondary {
  background: transparent;
  color: #db3c00;
  border: 1px solid #db3c00;
}
.btn--secondary:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .btn--secondary:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.btn--secondary.active,
.btn--secondary:active,
.btn--secondary:hover {
  background: #fff0ef;
  border-color: #db3c00;
  background-clip: padding-box;
}
.btn--secondary.disabled,
.btn--secondary.disabled:active,
.btn--secondary.disabled:focus,
.btn--secondary.disabled:hover,
.btn--secondary.disabled[disabled],
.btn--secondary[disabled],
.btn--secondary[disabled]:active,
.btn--secondary[disabled]:focus,
.btn--secondary[disabled]:hover,
.btn--secondary[disabled][disabled],
fieldset[disabled] .btn--secondary,
fieldset[disabled] .btn--secondary:active,
fieldset[disabled] .btn--secondary:focus,
fieldset[disabled] .btn--secondary:hover,
fieldset[disabled] .btn--secondary[disabled] {
  color: #8c8c8c;
  background: transparent;
  border-color: #c6c6c6;
  border-style: solid;
  box-shadow: none;
}
.btn--secondary.disabled .btn__icon,
.btn--secondary.disabled .btn__icon:not(.icon-spinner),
.btn--secondary.disabled:active .btn__icon,
.btn--secondary.disabled:active .btn__icon:not(.icon-spinner),
.btn--secondary.disabled:focus .btn__icon,
.btn--secondary.disabled:focus .btn__icon:not(.icon-spinner),
.btn--secondary.disabled:hover .btn__icon,
.btn--secondary.disabled:hover .btn__icon:not(.icon-spinner),
.btn--secondary.disabled[disabled] .btn__icon,
.btn--secondary.disabled[disabled] .btn__icon:not(.icon-spinner),
.btn--secondary[disabled] .btn__icon,
.btn--secondary[disabled] .btn__icon:not(.icon-spinner),
.btn--secondary[disabled]:active .btn__icon,
.btn--secondary[disabled]:active .btn__icon:not(.icon-spinner),
.btn--secondary[disabled]:focus .btn__icon,
.btn--secondary[disabled]:focus .btn__icon:not(.icon-spinner),
.btn--secondary[disabled]:hover .btn__icon,
.btn--secondary[disabled]:hover .btn__icon:not(.icon-spinner),
.btn--secondary[disabled][disabled] .btn__icon,
.btn--secondary[disabled][disabled] .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--secondary .btn__icon,
fieldset[disabled] .btn--secondary .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--secondary:active .btn__icon,
fieldset[disabled] .btn--secondary:active .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--secondary:focus .btn__icon,
fieldset[disabled] .btn--secondary:focus .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--secondary:hover .btn__icon,
fieldset[disabled] .btn--secondary:hover .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--secondary[disabled] .btn__icon,
fieldset[disabled] .btn--secondary[disabled] .btn__icon:not(.icon-spinner) {
  color: #8c8c8c;
}
.btn--tertiary {
  background: transparent;
  color: #37517c;
  border: 1px solid #a8a8a8;
}
.btn--tertiary:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .btn--tertiary:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.btn--tertiary.active,
.btn--tertiary:active,
.btn--tertiary:hover {
  background: #afc5e9;
  border-color: #6f6f6f;
  background-clip: padding-box;
}
.btn--tertiary.disabled,
.btn--tertiary.disabled:active,
.btn--tertiary.disabled:focus,
.btn--tertiary.disabled:hover,
.btn--tertiary.disabled[disabled],
.btn--tertiary[disabled],
.btn--tertiary[disabled]:active,
.btn--tertiary[disabled]:focus,
.btn--tertiary[disabled]:hover,
.btn--tertiary[disabled][disabled],
fieldset[disabled] .btn--tertiary,
fieldset[disabled] .btn--tertiary:active,
fieldset[disabled] .btn--tertiary:focus,
fieldset[disabled] .btn--tertiary:hover,
fieldset[disabled] .btn--tertiary[disabled] {
  color: #8c8c8c;
  background: transparent;
  border-color: #c6c6c6;
  border-style: solid;
  box-shadow: none;
}
.btn--tertiary.disabled .btn__icon,
.btn--tertiary.disabled .btn__icon:not(.icon-spinner),
.btn--tertiary.disabled:active .btn__icon,
.btn--tertiary.disabled:active .btn__icon:not(.icon-spinner),
.btn--tertiary.disabled:focus .btn__icon,
.btn--tertiary.disabled:focus .btn__icon:not(.icon-spinner),
.btn--tertiary.disabled:hover .btn__icon,
.btn--tertiary.disabled:hover .btn__icon:not(.icon-spinner),
.btn--tertiary.disabled[disabled] .btn__icon,
.btn--tertiary.disabled[disabled] .btn__icon:not(.icon-spinner),
.btn--tertiary[disabled] .btn__icon,
.btn--tertiary[disabled] .btn__icon:not(.icon-spinner),
.btn--tertiary[disabled]:active .btn__icon,
.btn--tertiary[disabled]:active .btn__icon:not(.icon-spinner),
.btn--tertiary[disabled]:focus .btn__icon,
.btn--tertiary[disabled]:focus .btn__icon:not(.icon-spinner),
.btn--tertiary[disabled]:hover .btn__icon,
.btn--tertiary[disabled]:hover .btn__icon:not(.icon-spinner),
.btn--tertiary[disabled][disabled] .btn__icon,
.btn--tertiary[disabled][disabled] .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--tertiary .btn__icon,
fieldset[disabled] .btn--tertiary .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--tertiary:active .btn__icon,
fieldset[disabled] .btn--tertiary:active .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--tertiary:focus .btn__icon,
fieldset[disabled] .btn--tertiary:focus .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--tertiary:hover .btn__icon,
fieldset[disabled] .btn--tertiary:hover .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--tertiary[disabled] .btn__icon,
fieldset[disabled] .btn--tertiary[disabled] .btn__icon:not(.icon-spinner) {
  color: #8c8c8c;
}
.btn--quinary {
  background: transparent;
  box-shadow: 0 0;
  color: #151515;
  border-radius: 4px;
  box-shadow: none;
  transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1) 50ms, border-width 0ms,
    padding 0ms, box-shadow 0ms;
}
.btn--quinary:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .btn--quinary:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.btn--quinary.active,
.btn--quinary:active,
.btn--quinary:hover {
  background: rgba(21, 21, 21, 0.1);
}
.btn--quinary.disabled,
.btn--quinary.disabled:active,
.btn--quinary.disabled:focus,
.btn--quinary.disabled:hover,
.btn--quinary.disabled[disabled],
.btn--quinary[disabled],
.btn--quinary[disabled]:active,
.btn--quinary[disabled]:focus,
.btn--quinary[disabled]:hover,
.btn--quinary[disabled][disabled],
fieldset[disabled] .btn--quinary,
fieldset[disabled] .btn--quinary:active,
fieldset[disabled] .btn--quinary:focus,
fieldset[disabled] .btn--quinary:hover,
fieldset[disabled] .btn--quinary[disabled] {
  color: #8c8c8c;
  background: transparent;
  box-shadow: none;
}
.btn--quinary:not(.btn--help):not([disabled]):not(.disabled).active,
.btn--quinary:not(.btn--help):not([disabled]):not(.disabled).btn--active,
.btn--quinary:not(.btn--help):not([disabled]):not(.disabled):active {
  color: #db3c00;
  background: #fff0ef;
}
.bar-item__list--is-dragging .btn--quinary:not(.btn--help):active,
.bar-item__list--is-dragging .btn--quinary:not(.btn--help):hover,
.btn--quinary:not(.btn--help) .asset__list--is--dragging:active,
.btn--quinary:not(.btn--help) .asset__list--is--dragging:hover,
.content-type-elements-pane__elements-list--is-dragging
  .btn--quinary:not(.btn--help):active,
.content-type-elements-pane__elements-list--is-dragging
  .btn--quinary:not(.btn--help):hover,
.rte--is-dragging .btn--quinary:not(.btn--help):active,
.rte--is-dragging .btn--quinary:not(.btn--help):hover {
  color: #151515;
  background: transparent;
}
.btn--quinary:not(.btn--help).drag-action:not([disabled]):not(.disabled) {
  cursor: grab;
}
.btn--quinary:not(.btn--help).drag-action:not([disabled]):not(.disabled):active {
  cursor: grabbing;
}
.drag-preview .btn--quinary:not(.btn--help).drag-action {
  color: #db3c00;
  background-color: #fff0ef;
}
.btn--primary-inverted {
  background: #fff;
  box-shadow: 0 0;
  color: #db3c00;
}
.btn--primary-inverted:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .btn--primary-inverted:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.btn--primary-inverted.active,
.btn--primary-inverted:active,
.btn--primary-inverted:hover {
  background: #fff0ef;
}
.btn--primary-inverted.disabled,
.btn--primary-inverted.disabled:active,
.btn--primary-inverted.disabled:focus,
.btn--primary-inverted.disabled:hover,
.btn--primary-inverted.disabled[disabled],
.btn--primary-inverted[disabled],
.btn--primary-inverted[disabled]:active,
.btn--primary-inverted[disabled]:focus,
.btn--primary-inverted[disabled]:hover,
.btn--primary-inverted[disabled][disabled],
fieldset[disabled] .btn--primary-inverted,
fieldset[disabled] .btn--primary-inverted:active,
fieldset[disabled] .btn--primary-inverted:focus,
fieldset[disabled] .btn--primary-inverted:hover,
fieldset[disabled] .btn--primary-inverted[disabled] {
  color: #8c8c8c;
  background: #dfdfdf;
  box-shadow: none;
}
.btn--secondary-inverted {
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.btn--secondary-inverted:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .btn--secondary-inverted:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.btn--secondary-inverted.active,
.btn--secondary-inverted:active,
.btn--secondary-inverted:hover {
  background: hsla(0, 0%, 100%, 0.16);
  border-color: #fff;
  background-clip: padding-box;
}
.btn--secondary-inverted.disabled,
.btn--secondary-inverted.disabled:active,
.btn--secondary-inverted.disabled:focus,
.btn--secondary-inverted.disabled:hover,
.btn--secondary-inverted.disabled[disabled],
.btn--secondary-inverted[disabled],
.btn--secondary-inverted[disabled]:active,
.btn--secondary-inverted[disabled]:focus,
.btn--secondary-inverted[disabled]:hover,
.btn--secondary-inverted[disabled][disabled],
fieldset[disabled] .btn--secondary-inverted,
fieldset[disabled] .btn--secondary-inverted:active,
fieldset[disabled] .btn--secondary-inverted:focus,
fieldset[disabled] .btn--secondary-inverted:hover,
fieldset[disabled] .btn--secondary-inverted[disabled] {
  color: #8c8c8c;
  background: transparent;
  border-color: #c6c6c6;
  border-style: solid;
  box-shadow: none;
}
.btn--secondary-inverted.disabled .btn__icon,
.btn--secondary-inverted.disabled .btn__icon:not(.icon-spinner),
.btn--secondary-inverted.disabled:active .btn__icon,
.btn--secondary-inverted.disabled:active .btn__icon:not(.icon-spinner),
.btn--secondary-inverted.disabled:focus .btn__icon,
.btn--secondary-inverted.disabled:focus .btn__icon:not(.icon-spinner),
.btn--secondary-inverted.disabled:hover .btn__icon,
.btn--secondary-inverted.disabled:hover .btn__icon:not(.icon-spinner),
.btn--secondary-inverted.disabled[disabled] .btn__icon,
.btn--secondary-inverted.disabled[disabled] .btn__icon:not(.icon-spinner),
.btn--secondary-inverted[disabled] .btn__icon,
.btn--secondary-inverted[disabled] .btn__icon:not(.icon-spinner),
.btn--secondary-inverted[disabled]:active .btn__icon,
.btn--secondary-inverted[disabled]:active .btn__icon:not(.icon-spinner),
.btn--secondary-inverted[disabled]:focus .btn__icon,
.btn--secondary-inverted[disabled]:focus .btn__icon:not(.icon-spinner),
.btn--secondary-inverted[disabled]:hover .btn__icon,
.btn--secondary-inverted[disabled]:hover .btn__icon:not(.icon-spinner),
.btn--secondary-inverted[disabled][disabled] .btn__icon,
.btn--secondary-inverted[disabled][disabled] .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--secondary-inverted .btn__icon,
fieldset[disabled] .btn--secondary-inverted .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--secondary-inverted:active .btn__icon,
fieldset[disabled]
  .btn--secondary-inverted:active
  .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--secondary-inverted:focus .btn__icon,
fieldset[disabled] .btn--secondary-inverted:focus .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--secondary-inverted:hover .btn__icon,
fieldset[disabled] .btn--secondary-inverted:hover .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--secondary-inverted[disabled] .btn__icon,
fieldset[disabled]
  .btn--secondary-inverted[disabled]
  .btn__icon:not(.icon-spinner) {
  color: #8c8c8c;
}
.btn--destructive-primary {
  background: #db0000;
  box-shadow: 0 0;
  color: #fff;
}
.btn--destructive-primary:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .btn--destructive-primary:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.btn--destructive-primary.active,
.btn--destructive-primary:active,
.btn--destructive-primary:hover {
  background: #b10202;
}
.btn--destructive-primary.disabled,
.btn--destructive-primary.disabled:active,
.btn--destructive-primary.disabled:focus,
.btn--destructive-primary.disabled:hover,
.btn--destructive-primary.disabled[disabled],
.btn--destructive-primary[disabled],
.btn--destructive-primary[disabled]:active,
.btn--destructive-primary[disabled]:focus,
.btn--destructive-primary[disabled]:hover,
.btn--destructive-primary[disabled][disabled],
fieldset[disabled] .btn--destructive-primary,
fieldset[disabled] .btn--destructive-primary:active,
fieldset[disabled] .btn--destructive-primary:focus,
fieldset[disabled] .btn--destructive-primary:hover,
fieldset[disabled] .btn--destructive-primary[disabled] {
  color: #8c8c8c;
  background: #dfdfdf;
  box-shadow: none;
}
.btn--destructive-secondary {
  background: transparent;
  color: #db0000;
  border: 1px solid #db0000;
}
.btn--destructive-secondary:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .btn--destructive-secondary:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.btn--destructive-secondary.active,
.btn--destructive-secondary:active,
.btn--destructive-secondary:hover {
  background: #b10202;
  border-color: #b10202;
  background-clip: padding-box;
}
.btn--destructive-secondary.disabled,
.btn--destructive-secondary.disabled:active,
.btn--destructive-secondary.disabled:focus,
.btn--destructive-secondary.disabled:hover,
.btn--destructive-secondary.disabled[disabled],
.btn--destructive-secondary[disabled],
.btn--destructive-secondary[disabled]:active,
.btn--destructive-secondary[disabled]:focus,
.btn--destructive-secondary[disabled]:hover,
.btn--destructive-secondary[disabled][disabled],
fieldset[disabled] .btn--destructive-secondary,
fieldset[disabled] .btn--destructive-secondary:active,
fieldset[disabled] .btn--destructive-secondary:focus,
fieldset[disabled] .btn--destructive-secondary:hover,
fieldset[disabled] .btn--destructive-secondary[disabled] {
  color: #8c8c8c;
  background: transparent;
  border-color: #c6c6c6;
  border-style: solid;
  box-shadow: none;
}
.btn--destructive-secondary.disabled .btn__icon,
.btn--destructive-secondary.disabled .btn__icon:not(.icon-spinner),
.btn--destructive-secondary.disabled:active .btn__icon,
.btn--destructive-secondary.disabled:active .btn__icon:not(.icon-spinner),
.btn--destructive-secondary.disabled:focus .btn__icon,
.btn--destructive-secondary.disabled:focus .btn__icon:not(.icon-spinner),
.btn--destructive-secondary.disabled:hover .btn__icon,
.btn--destructive-secondary.disabled:hover .btn__icon:not(.icon-spinner),
.btn--destructive-secondary.disabled[disabled] .btn__icon,
.btn--destructive-secondary.disabled[disabled] .btn__icon:not(.icon-spinner),
.btn--destructive-secondary[disabled] .btn__icon,
.btn--destructive-secondary[disabled] .btn__icon:not(.icon-spinner),
.btn--destructive-secondary[disabled]:active .btn__icon,
.btn--destructive-secondary[disabled]:active .btn__icon:not(.icon-spinner),
.btn--destructive-secondary[disabled]:focus .btn__icon,
.btn--destructive-secondary[disabled]:focus .btn__icon:not(.icon-spinner),
.btn--destructive-secondary[disabled]:hover .btn__icon,
.btn--destructive-secondary[disabled]:hover .btn__icon:not(.icon-spinner),
.btn--destructive-secondary[disabled][disabled] .btn__icon,
.btn--destructive-secondary[disabled][disabled] .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--destructive-secondary .btn__icon,
fieldset[disabled] .btn--destructive-secondary .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--destructive-secondary:active .btn__icon,
fieldset[disabled]
  .btn--destructive-secondary:active
  .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--destructive-secondary:focus .btn__icon,
fieldset[disabled]
  .btn--destructive-secondary:focus
  .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--destructive-secondary:hover .btn__icon,
fieldset[disabled]
  .btn--destructive-secondary:hover
  .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--destructive-secondary[disabled] .btn__icon,
fieldset[disabled]
  .btn--destructive-secondary[disabled]
  .btn__icon:not(.icon-spinner) {
  color: #8c8c8c;
}
.btn--destructive-secondary .btn__icon {
  color: #fd3030;
  transition: color 0.25s cubic-bezier(0.23, 1, 0.32, 1) 50ms;
}
.btn--destructive-secondary.active,
.btn--destructive-secondary.active .btn__icon:not(.icon-spinner),
.btn--destructive-secondary:active,
.btn--destructive-secondary:active .btn__icon:not(.icon-spinner),
.btn--destructive-secondary:hover,
.btn--destructive-secondary:hover .btn__icon:not(.icon-spinner) {
  color: #fff;
}
.btn--floating {
  background: transparent;
  color: #37517c;
  border: 1px solid #a8a8a8;
}
.btn--floating:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .btn--floating:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.btn--floating.active,
.btn--floating:active,
.btn--floating:hover {
  background: #afc5e9;
  border-color: #6f6f6f;
  background-clip: padding-box;
}
.btn--floating.disabled,
.btn--floating.disabled:active,
.btn--floating.disabled:focus,
.btn--floating.disabled:hover,
.btn--floating.disabled[disabled],
.btn--floating[disabled],
.btn--floating[disabled]:active,
.btn--floating[disabled]:focus,
.btn--floating[disabled]:hover,
.btn--floating[disabled][disabled],
fieldset[disabled] .btn--floating,
fieldset[disabled] .btn--floating:active,
fieldset[disabled] .btn--floating:focus,
fieldset[disabled] .btn--floating:hover,
fieldset[disabled] .btn--floating[disabled] {
  color: #8c8c8c;
  background: transparent;
  border-color: #c6c6c6;
  border-style: solid;
  box-shadow: none;
}
.btn--floating.disabled .btn__icon,
.btn--floating.disabled .btn__icon:not(.icon-spinner),
.btn--floating.disabled:active .btn__icon,
.btn--floating.disabled:active .btn__icon:not(.icon-spinner),
.btn--floating.disabled:focus .btn__icon,
.btn--floating.disabled:focus .btn__icon:not(.icon-spinner),
.btn--floating.disabled:hover .btn__icon,
.btn--floating.disabled:hover .btn__icon:not(.icon-spinner),
.btn--floating.disabled[disabled] .btn__icon,
.btn--floating.disabled[disabled] .btn__icon:not(.icon-spinner),
.btn--floating[disabled] .btn__icon,
.btn--floating[disabled] .btn__icon:not(.icon-spinner),
.btn--floating[disabled]:active .btn__icon,
.btn--floating[disabled]:active .btn__icon:not(.icon-spinner),
.btn--floating[disabled]:focus .btn__icon,
.btn--floating[disabled]:focus .btn__icon:not(.icon-spinner),
.btn--floating[disabled]:hover .btn__icon,
.btn--floating[disabled]:hover .btn__icon:not(.icon-spinner),
.btn--floating[disabled][disabled] .btn__icon,
.btn--floating[disabled][disabled] .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--floating .btn__icon,
fieldset[disabled] .btn--floating .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--floating:active .btn__icon,
fieldset[disabled] .btn--floating:active .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--floating:focus .btn__icon,
fieldset[disabled] .btn--floating:focus .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--floating:hover .btn__icon,
fieldset[disabled] .btn--floating:hover .btn__icon:not(.icon-spinner),
fieldset[disabled] .btn--floating[disabled] .btn__icon,
fieldset[disabled] .btn--floating[disabled] .btn__icon:not(.icon-spinner) {
  color: #8c8c8c;
}
.btn--square {
  padding: 0;
  letter-spacing: 0;
  border-radius: 50%;
}
.btn--square-m {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}
.btn--square-m [class*=" icon-"],
.btn--square-m [class^="icon-"] {
  font-family: kentico-icons;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
}
.btn--square-xs {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
.btn--square-xs [class*=" icon-"],
.btn--square-xs [class^="icon-"] {
  font-family: kentico-icons;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
}
.btn--square-xs.btn--quinary {
  background: transparent;
  box-shadow: 0 0;
  color: #151515;
  border-radius: 4px;
  box-shadow: none;
  transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1) 50ms, border-width 0ms,
    padding 0ms, box-shadow 0ms;
}
.btn--square-xs.btn--quinary:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .btn--square-xs.btn--quinary:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.btn--square-xs.btn--quinary.active,
.btn--square-xs.btn--quinary:active,
.btn--square-xs.btn--quinary:hover {
  background: rgba(21, 21, 21, 0.1);
}
.btn--square-xs.btn--quinary.disabled,
.btn--square-xs.btn--quinary.disabled:active,
.btn--square-xs.btn--quinary.disabled:focus,
.btn--square-xs.btn--quinary.disabled:hover,
.btn--square-xs.btn--quinary.disabled[disabled],
.btn--square-xs.btn--quinary[disabled],
.btn--square-xs.btn--quinary[disabled]:active,
.btn--square-xs.btn--quinary[disabled]:focus,
.btn--square-xs.btn--quinary[disabled]:hover,
.btn--square-xs.btn--quinary[disabled][disabled],
fieldset[disabled] .btn--square-xs.btn--quinary,
fieldset[disabled] .btn--square-xs.btn--quinary:active,
fieldset[disabled] .btn--square-xs.btn--quinary:focus,
fieldset[disabled] .btn--square-xs.btn--quinary:hover,
fieldset[disabled] .btn--square-xs.btn--quinary[disabled] {
  color: #8c8c8c;
  background: transparent;
  box-shadow: none;
}
.btn--square-xs.btn--quinary:not(.btn--help):not([disabled]):not(.disabled).active,
.btn--square-xs.btn--quinary:not(.btn--help):not([disabled]):not(.disabled).btn--active,
.btn--square-xs.btn--quinary:not(.btn--help):not([disabled]):not(.disabled):active {
  color: #db3c00;
  background: #fff0ef;
}
.bar-item__list--is-dragging
  .btn--square-xs.btn--quinary:not(.btn--help):active,
.bar-item__list--is-dragging .btn--square-xs.btn--quinary:not(.btn--help):hover,
.btn--square-xs.btn--quinary:not(.btn--help) .asset__list--is--dragging:active,
.btn--square-xs.btn--quinary:not(.btn--help) .asset__list--is--dragging:hover,
.content-type-elements-pane__elements-list--is-dragging
  .btn--square-xs.btn--quinary:not(.btn--help):active,
.content-type-elements-pane__elements-list--is-dragging
  .btn--square-xs.btn--quinary:not(.btn--help):hover,
.rte--is-dragging .btn--square-xs.btn--quinary:not(.btn--help):active,
.rte--is-dragging .btn--square-xs.btn--quinary:not(.btn--help):hover {
  color: #151515;
  background: transparent;
}
.btn--square-xs.btn--quinary:not(.btn--help).drag-action:not([disabled]):not(.disabled) {
  cursor: grab;
}
.btn--square-xs.btn--quinary:not(.btn--help).drag-action:not([disabled]):not(.disabled):active {
  cursor: grabbing;
}
.drag-preview .btn--square-xs.btn--quinary:not(.btn--help).drag-action {
  color: #db3c00;
  background-color: #fff0ef;
}
.btn--square-xs.btn--quinary-destructive-activated,
.btn--square-xs.btn--quinary-destructive:hover {
  color: #fff;
  background-color: #b10202;
}
.btn--square-sm {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
}
.btn--square-sm [class*=" icon-"],
.btn--square-sm [class^="icon-"] {
  font-family: kentico-icons;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
}
.btn--square-l {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
}
.btn--square-l [class*=" icon-"],
.btn--square-l [class^="icon-"] {
  font-family: kentico-icons;
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
}
.btn--xs {
  height: 32px;
  padding: 0 15px;
  font-weight: 500;
  letter-spacing: 0.1ch;
  text-transform: uppercase;
}
.btn--xs,
.btn--xs.btn--quinary {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 12px;
  line-height: 16px;
  min-width: 48px;
}
.btn--xs.btn--quinary {
  height: 24px;
  padding: 0 4px;
  font-weight: 400;
  text-transform: none;
}
.btn--small {
  height: 40px;
  padding: 0 23px;
  font-size: 12px;
}
.btn--block {
  width: 100%;
}
.btn--block + .btn--block {
  margin-top: 8px;
  margin-left: 0;
}
.btn--help {
  cursor: help;
}
.btn[data-overlay-text] {
  transform: scale(0.001);
  transition: none;
}
.btn[data-overlay-text]:after {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #151515;
  text-transform: none;
  content: attr(data-overlay-text);
  transform: scale(1000);
}
.btn__icon {
  margin-right: 4px;
  font-size: 16px;
}
.btn__icon--trailing {
  margin-right: 0;
  margin-left: 4px;
}
.btn__icon--large {
  margin-right: 8px;
  font-size: 20px;
}
.btn__icon--white {
  color: #fff;
}
.combo-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  overflow: hidden;
  color: #151515;
  border: 1px solid #a8a8a8;
  border-radius: 5000px;
  outline: 0;
  box-sizing: border-box;
  transition: border 0.25s cubic-bezier(0.23, 1, 0.32, 1) 50ms;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.combo-box .dropdown-selected {
  min-width: 100%;
}
.combo-box .dropdown-selected .dropdown-selected__pane {
  display: flex;
  min-width: 100%;
  padding: 0 16px;
  justify-content: space-between;
}
.combo-box .dropdown-selected .dropdown-selected__item-icon {
  margin-left: 8px;
  font-size: 16px;
  color: #000;
}
.combo-box::placeholder {
  color: #6f6f6f;
  -webkit-text-fill-color: #6f6f6f;
  opacity: 1;
}
.combo-box:focus:not(.combo-box--is-disabled) {
  border-color: #141619;
  outline: none;
}
.combo-box:focus:not(.combo-box--is-disabled)::placeholder {
  color: transparent;
}
.combo-box--has-error,
.combo-box--has-error:focus:not(.combo-box--is-disabled) {
  border: 1px solid #db0000;
}
.combo-box--with-default-option {
  font-size: inherit;
  color: #6f6f6f;
}
.combo-box--is-disabled,
.combo-box--is-disabled .dropdown-selected__pane,
.combo-box[disabled],
.combo-box[readonly],
fieldset[disabled] .combo-box {
  color: #8c8c8c;
  cursor: not-allowed;
  background-color: #dfdfdf;
  border-color: #c6c6c6;
}
.combo-box--is-disabled .dropdown-selected__item-icon,
.combo-box--is-disabled .dropdown-selected__pane .dropdown-selected__item-icon,
.combo-box[disabled] .dropdown-selected__item-icon,
.combo-box[readonly] .dropdown-selected__item-icon,
fieldset[disabled] .combo-box .dropdown-selected__item-icon {
  color: #8c8c8c;
}
.dropdown-options {
  z-index: 2400;
  min-width: 180px;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03), 0 8px 32px 0 rgba(16, 33, 60, 0.24);
}
.dropdown-options li {
  list-style: none;
}
.dropdown-options-group--is-scrollable {
  max-height: 40vh;
  overflow-y: auto;
}
.dropdown-options-group > :first-child {
  margin-top: 8px;
}
.dropdown-options-group > :last-child {
  margin-bottom: 8px;
}
.dropdown-options-group > a {
  display: flex;
  width: 100%;
  text-decoration: none;
}
.dropdown-options-group > a:focus,
.dropdown-options-group > a:hover {
  text-decoration: none;
}
.dropdown-options-group + .dropdown-options-group {
  border-top: 1px solid #dde0e4;
}
.dropdown-options-group + .dropdown-options-group:before {
  display: block;
  height: 0;
  margin-bottom: 8px;
  overflow: hidden;
  content: "";
}
.dropdown-options-group .dropdown-options-title {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 0 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  color: #151515;
  white-space: nowrap;
}
.dropdown-options__pane {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  flex-shrink: 0;
  flex-flow: column;
  max-height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.dropdown-options__pane--longer {
  max-height: calc(100vh - 16px);
}
.dropdown-option {
  display: flex;
  width: 100%;
  color: #151515;
  cursor: pointer;
}
.dropdown-option a {
  color: #151515;
}
.dropdown-option a:focus,
.dropdown-option a:hover {
  text-decoration: none;
}
.dropdown-options:not(.dropdown-options--is-selector)
  .dropdown-option:not(.dropdown-option--is-disabled):hover {
  color: #151515;
  background-color: rgba(21, 21, 21, 0.1);
}
.dropdown-options:not(.dropdown-options--is-selector)
  .dropdown-option:not(.dropdown-option--is-disabled):hover.dropdown-option--is-destructive {
  color: #fff;
  background-color: #db0000;
}
.dropdown-option--is-highlighted {
  background-color: rgba(21, 21, 21, 0.1);
}
.dropdown-option--is-selected,
.dropdown-option--is-selected a {
  color: #953000;
  background-color: #fff0ef;
}
.dropdown-option--is-selected.dropdown-option--is-highlighted,
.dropdown-option--is-selected.dropdown-option--is-highlighted a,
.dropdown-options:not(.dropdown-options--is-selector)
  .dropdown-option--is-selected:hover,
.dropdown-options:not(.dropdown-options--is-selector)
  .dropdown-option--is-selected:hover
  a {
  background-color: #ffd6d1;
}
.dropdown-option--is-disabled {
  color: #8c8c8c;
  cursor: not-allowed;
}
.dropdown-option--is-disabled:hover {
  color: #8c8c8c;
  background-color: initial;
}
.dropdown-option__pane {
  position: relative;
  display: flex;
  flex-flow: row;
  flex-shrink: 0;
  width: 100%;
  padding: 8px 16px;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
}
.dropdown-option__pane--caption-option {
  justify-content: start;
}
.dropdown-options__pane--has-trailing-elements .dropdown-option__pane {
  min-height: 36px;
}
.dropdown-options__pane--has-trailing-elements
  .dropdown-option__pane
  > :last-child:not(.dropdown-option__icon):not(.dropdown-option__badge-pane) {
  padding-right: 28px;
}
.dropdown-option__name {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 auto;
}
.dropdown-options__pane--has-trailing-elements .dropdown-option__name {
  line-height: 20px;
}
.dropdown-option__action-pane {
  display: flex;
  margin-left: 24px;
}
.dropdown-option__action {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 4px;
}
.dropdown-option__badge-pane {
  display: flex;
  min-width: 20px;
  min-height: 20px;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  flex: 0 0 auto;
}
.dropdown-option__badge {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  display: flex;
  height: 16px;
  min-width: 16px;
  padding: 0 2px;
  color: #151515;
  background-color: #dfdfdf;
  align-items: center;
  justify-content: space-around;
  border-radius: 5000px;
}
.dropdown-option__badge:empty {
  height: 8px;
  min-width: 8px;
}
.dropdown-option__badge--orange {
  color: #151515;
  background-color: #f49100;
}
.dropdown-option__badge--red {
  color: #fff;
  background-color: #db0000;
}
.dropdown-option__action-glyph {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  visibility: hidden;
}
.dropdown-option__action-glyph--is-selected {
  color: #fff;
  background: #0093ff;
  visibility: visible;
}
.dropdown-option__icon {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}
.dropdown-option__icon i {
  font-size: 20px;
}
.dropdown-option__icon--left {
  min-width: 28px;
  min-height: 28px;
  margin-right: 8px;
  margin-left: 0;
}
.dropdown-option__icon--loading {
  color: #525252;
}
.dropdown-option__icon--loading.icon-spinner {
  line-height: 1.5;
  animation-direction: normal;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: spin-500;
  animation-timing-function: linear;
}
.dropdown-option-separator {
  height: 1px;
  min-height: 1px;
  overflow: hidden;
  background-color: #dde0e4;
}
.dropdown-option + .dropdown-option-separator,
.dropdown-option-separator + .dropdown-option {
  margin-top: 8px;
}
.dropdown-item {
  padding: 12px 16px;
}
.dropdown-selected {
  display: flex;
  align-items: center;
  align-self: stretch;
}
.dropdown-selected,
.dropdown-selected__pane,
.dropdown-selected__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-selected__pane {
  display: inherit;
  padding: 0 8px;
  color: #151515;
  cursor: pointer;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
}
.dropdown-selected--is-placeholder .dropdown-selected__pane {
  color: #6f6f6f;
}
.dropdown-selected__item-icon {
  margin-left: 12px;
  font-size: 12px;
}
.form__group {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 24px;
  color: #151515;
}
.form__group--clipboard {
  position: relative;
}
.form__group--medium-width {
  min-width: 288px;
}
.form__group:first-child {
  margin-top: 0;
}
.form__group--is-compact {
  margin-top: 8px;
}
.form__input-with-buttons-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.form__input-with-buttons-wrapper--is-edited {
  flex-wrap: wrap;
}
.form__input-with-buttons-wrapper--message-is-centered {
  justify-content: center;
}
.form__group-overlay {
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.form__group-overlay,
.form__section-title {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #151515;
}
.form__section-title {
  display: inline;
  font-weight: 700;
}
.form__section .form__section .form__section-title {
  font-weight: 700;
}
.form__section-status,
.form__section .form__section .form__section-title {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 14px;
  line-height: 16px;
}
.form__section-status {
  display: inline-flex;
  margin-left: 8px;
  font-weight: 400;
}
.form__section-status--failed {
  color: #db0000;
}
.form__section-status--failed:before {
  font-family: kentico-icons;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
  padding-right: 8px;
  color: #fd3030;
  content: "\e693";
}
.form__section-guideline {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 24px;
  color: #525252;
}
.form__section + .form__section {
  margin-top: 24px;
}
.form__section-meta {
  margin-bottom: 24px;
}
.form__label {
  font-weight: 400;
  display: block;
  padding-left: 0;
  margin-bottom: 10px;
  color: #151515;
}
.form__label,
.form__label--is-bold {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 14px;
  line-height: 16px;
}
.form__label--is-bold {
  font-weight: 700;
}
.form__text-field {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  width: 100%;
  height: 40px;
  min-width: 0;
  padding: 9px 16px;
  color: #151515;
  background-color: initial;
  border: 1px solid #a8a8a8;
  border-radius: 20px;
  transition: border 0.25s cubic-bezier(0.23, 1, 0.32, 1) 50ms;
  resize: vertical;
}
.form__text-field::placeholder {
  color: #6f6f6f;
  -webkit-text-fill-color: #6f6f6f;
  opacity: 1;
}
.form__text-field:focus {
  color: #151515;
  border-color: #141619;
  outline: none;
}
.form__text-field[disabled],
fieldset[disabled] .form__text-field {
  color: #8c8c8c;
  cursor: not-allowed;
  background-color: #dfdfdf;
  border-color: #c6c6c6;
  opacity: 1;
}
.form__text-field[readonly] {
  color: #151515;
  text-overflow: ellipsis;
  cursor: text;
  background-color: #dfdfdf;
  border-color: #c6c6c6;
}
.form__text-field[readonly]:focus {
  border-color: #c6c6c6;
}
textarea.form__text-field {
  height: auto;
}
.form__text-field--has-error {
  border: 1px solid #b10202;
}
.form__text-field--has-error:focus,
.form__text-field--has-error:hover {
  border-color: #b10202;
}
.form__text-field--300 {
  min-height: 40px;
  resize: none;
}
.form__text-field--not-editable[disabled] {
  cursor: not-allowed;
  background-color: #dfdfdf;
  border: none;
}
.form__text-field--with-placeholder:focus::placeholder {
  color: #6f6f6f;
  -webkit-text-fill-color: #6f6f6f;
  opacity: 1;
  content: attr(placeholder);
}
.form__icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  font-size: 20px;
  cursor: pointer;
  background-color: initial;
  background-image: none;
  border: 0;
  border-radius: 50%;
  touch-action: manipulation;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.form__icon-btn.disabled,
.form__icon-btn[disabled],
fieldset[disabled] .form__icon-btn {
  pointer-events: none;
  cursor: not-allowed;
}
.form__icon-btn.active,
.form__icon-btn:active {
  background-image: none;
  outline: 0;
}
.form__icon-btn + .form__icon-btn,
.form__text-field + .form__icon-btn {
  margin-left: 2px;
}
.form__icon-btn > i {
  font-size: 20px;
}
.form__icon-btn--is-primary {
  color: #fff;
  background-color: #db3c00;
}
.form__icon-btn--is-primary:not(.disabled) {
  box-shadow: 0 8px 14px 2px rgba(244, 92, 35, 0.14),
    0 6px 20px 5px rgba(244, 92, 35, 0.12),
    0 8px 10px -5px rgba(244, 92, 35, 0.2);
}
.form__icon-btn--is-primary:active,
.form__icon-btn--is-primary:hover {
  background-color: #953000;
}
.form__icon-btn--is-primary:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .form__icon-btn--is-primary:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.form__icon-btn--is-secondary {
  color: #db3c00;
  border: 1px solid #db3c00;
}
.form__icon-btn--is-secondary:active,
.form__icon-btn--is-secondary:hover {
  background-color: #fff0ef;
  border-color: #db3c00;
}
.form__icon-btn--is-secondary:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible
  .form__icon-btn--is-secondary:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.form__icon-btn--is-tertiary {
  color: #37517c;
  border: 1px solid #a8a8a8;
}
.form__icon-btn--is-tertiary:active,
.form__icon-btn--is-tertiary:hover {
  background-color: #afc5e9;
  border-color: #6f6f6f;
}
.form__icon-btn--is-tertiary:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .form__icon-btn--is-tertiary:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.form__icon-btn--with-height-auto {
  height: auto;
}
input[type="checkbox"]:disabled {
  cursor: not-allowed;
}
.form__alert {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #db0000;
  overflow-wrap: break-word;
}
.form__alert,
.form__info {
  font-family: GT Walsheim Pro, sans-serif;
  margin-top: 8px;
}
.form__info {
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  color: #525252;
}
.form__group + .form__action {
  margin-top: 16px;
}
.form__action + .form__action,
.form__text-field + .form__action {
  margin-left: 8px;
}
.form__message {
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0;
  color: #000;
}
.form__message,
.status {
  font-family: GT Walsheim Pro, sans-serif;
  font-weight: 400;
}
.status {
  display: flex;
  margin-left: 12px;
  font-size: 14px;
  line-height: 16px;
  color: #525252;
}
.status:first-child {
  margin-left: 0;
}
.status:before {
  font-family: kentico-icons;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
  padding-right: 8px;
  color: #cd7900;
  content: "\e926";
}
.status--filled-correctly,
.status--is-ready {
  color: #007f63;
}
.status--filled-correctly:before,
.status--is-ready:before {
  color: #00a07d;
  content: "\e675";
}
.status--is-all-done {
  color: #525252;
}
.status--is-all-done:before {
  color: #525252;
  content: "\e702";
}
.status--filled-correctly {
  color: #007f63;
}
.status--filled-correctly:before {
  color: #00a07d;
  content: "\e702";
}
.status--is-missing {
  color: #a35f00;
}
.status--is-missing:before {
  content: "\e693";
}
.link-dialog__detail .status--is-missing {
  margin-right: 8px;
}
.status--filled-incorrectly {
  color: #a35f00;
}
.status--filled-incorrectly:before {
  content: "\e693";
}
.status--filled-incorrectly.status--validation-failed,
.status--validation-failed {
  color: #db0000;
}
.status--validation-failed:before {
  color: #fd3030;
  content: "\e925";
}
.multi-select {
  display: flex;
  align-items: center;
  min-height: 40px;
  cursor: text;
  background-color: initial;
  border: 1px solid #a8a8a8;
  border-radius: 20px;
  transition: border 0.25s cubic-bezier(0.23, 1, 0.32, 1) 50ms;
}
.multi-select--is-disabled {
  cursor: not-allowed;
  background-color: #dfdfdf;
  border-color: #c6c6c6;
}
.multi-select--has-focus {
  color: #151515;
  border-color: #141619;
  outline: none;
}
.multi-select--has-error {
  border-color: #db0000;
}
.multi-select__search-field {
  display: flex;
  min-width: 0;
  align-items: center;
  padding-left: 12px;
  word-break: break-all;
  border: none;
}
.content-item-element__content .multi-select__search-field {
  padding-left: 4px;
}
.multi-select__search-field-placeholder {
  margin: 2px 8px 0 -8px;
  overflow: hidden;
  color: #6f6f6f;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.multi-select__search-text {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  min-width: 1px;
  margin: 2px 8px 0 4px;
  color: #151515;
}
.content-item-element__content .multi-select__search-text {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 0;
}
.multi-select__dropdown {
  max-width: 512px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03), 0 8px 32px 0 rgba(16, 33, 60, 0.24);
}
.tether-out-of-bounds-bottom.tether-element-attached-bottom
  .multi-select__dropdown,
.tether-out-of-bounds-top.tether-element-attached-top .multi-select__dropdown {
  display: none;
}
.multi-select__dropdown-options {
  max-height: 40vh;
  padding: 8px 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.multi-select__dropdown-option {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  min-width: 200px;
  padding: 8px 16px;
  color: #151515;
  cursor: pointer;
  background-color: #fff;
}
.multi-select__dropdown-option--is-highlighted {
  background-color: rgba(21, 21, 21, 0.1);
}
.multi-select__dropdown-option--is-selected {
  color: #953000;
  background-color: #fff0ef;
}
.multi-select__dropdown-option--is-selected.multi-select__dropdown-option--is-highlighted {
  background-color: #ffd6d1;
}
.multi-select__dropdown-option--is-category {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 16px 8px;
  color: #151515;
  cursor: not-allowed;
}
.multi-select__dropdown-option--is-category:first-child {
  padding-top: 8px;
}
.multi-select__dropdown-option--is-category:not(:first-child) {
  margin-top: 8px;
  border-top: 1px solid #dde0e4;
}
.multi-select__dropdown-option--is-disabled {
  color: #dfdfdf;
  cursor: default;
}
.multi-select__dropdown-option--red:before {
  background-color: #db0000;
}
.multi-select__dropdown-option--pink:before,
.multi-select__dropdown-option--red:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #fff;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--pink:before {
  background-color: #d30d6d;
}
.multi-select__dropdown-option--purple:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #151515;
  background-color: #d3acec;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--deep-purple:before {
  background-color: #8a2bc5;
}
.multi-select__dropdown-option--deep-purple:before,
.multi-select__dropdown-option--indigo:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #fff;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--indigo:before {
  background-color: #3655ba;
}
.multi-select__dropdown-option--blue:before {
  background-color: #abbaed;
}
.multi-select__dropdown-option--blue:before,
.multi-select__dropdown-option--cyan:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #151515;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--cyan:before {
  background-color: #74d1ff;
}
.multi-select__dropdown-option--teal:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #151515;
  background-color: #00e0b1;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--green:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #fff;
  background-color: #007f63;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--green-inverted:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #151515;
  background-color: #00bf96;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--light-green:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #fff;
  background-color: #4b7c00;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--lime:before {
  background-color: #89dc00;
}
.multi-select__dropdown-option--lime:before,
.multi-select__dropdown-option--yellow:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #151515;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--yellow:before {
  background-color: #ffb784;
}
.multi-select__dropdown-option--amber:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #151515;
  background-color: #fe9a9a;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--orange:before {
  background-color: #c64300;
}
.multi-select__dropdown-option--brown:before,
.multi-select__dropdown-option--orange:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #fff;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--brown:before {
  background-color: #a35f00;
}
.multi-select__dropdown-option--grey:before {
  background-color: #69707c;
}
.multi-select__dropdown-option--dark-blue:before,
.multi-select__dropdown-option--grey:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #fff;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--dark-blue:before {
  background-color: #3655ba;
}
.multi-select__dropdown-option--dark-blue-inverted:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #151515;
  background-color: #7d95e3;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--cool-grey:before {
  background-color: #c2c7cf;
}
.multi-select__dropdown-option--cool-grey:before,
.multi-select__dropdown-option--light-grey:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #151515;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--light-grey:before {
  background-color: #dde0e4;
}
.multi-select__dropdown-option--product:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #fff;
  background-color: #db3c00;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--product-disabled:before {
  background-color: #ffb4a9;
}
.multi-select__dropdown-option--product-disabled:before,
.multi-select__dropdown-option--warning:before {
  width: 20px;
  height: 20px;
  margin: 4px 12px 4px 4px;
  color: #151515;
  border-radius: 50%;
  content: "";
}
.multi-select__dropdown-option--warning:before {
  background-color: #f49100;
}
.multi-select__dropdown-user-option {
  flex-direction: column;
  align-items: flex-start;
}
.multi-select__collapsible-dropdown-option {
  display: flex;
  flex-direction: row;
}
.multi-select__dropdown-option-caret {
  padding-right: 8px;
  padding-left: 8px;
  margin-left: -8px;
}
.multi-select__dropdown-option-caret-placeholder {
  min-width: 24px;
}
.multi-select__dropdown-option-name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.multi-select__dropdown-option-aux {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  margin-top: 4px;
  overflow: hidden;
  color: #525252;
  text-overflow: ellipsis;
}
.multi-select__dropdown-option--is-selected .multi-select__dropdown-option-aux {
  color: #953000;
}
.multi-select__option-wrapper {
  max-width: 100%;
}
.multi-select__option {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  flex-wrap: nowrap;
  max-width: calc(100% - 4px);
  margin: 3px 1px 1px 3px;
  color: #151515;
  background: #db3c00;
  border-radius: 5000px;
}
.multi-select__option--is-disabled {
  cursor: default;
  background-color: #c6c6c6;
}
.multi-select--is-disabled .multi-select__option {
  cursor: not-allowed;
  background-color: #c6c6c6;
}
.content-item-element__content .multi-select__option--is-disabled {
  color: #151515;
  cursor: default;
  background-color: #dfdfdf;
}
.multi-select__option--red {
  color: #fff;
  background-color: #db0000;
}
.multi-select__option--red > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #fff;
}
.multi-select__option--red > .multi-select__option-remove-button:hover {
  background-color: #a80000;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--red > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #fff;
}
.multi-select__option--red > .multi-select__option-name--is-clickable:hover {
  background-color: #a80000;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--red:hover {
  color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--pink {
  color: #fff;
  background-color: #d30d6d;
}
.multi-select__option--pink > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #fff;
}
.multi-select__option--pink > .multi-select__option-remove-button:hover {
  background-color: #a30a54;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--pink > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #fff;
}
.multi-select__option--pink > .multi-select__option-name--is-clickable:hover {
  background-color: #a30a54;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--pink:hover {
  color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--purple {
  color: #151515;
  background-color: #d3acec;
}
.multi-select__option--purple > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--purple > .multi-select__option-remove-button:hover {
  background-color: #bd83e3;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--purple > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--purple > .multi-select__option-name--is-clickable:hover {
  background-color: #bd83e3;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--purple:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--deep-purple {
  color: #fff;
  background-color: #8a2bc5;
}
.multi-select__option--deep-purple > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #fff;
}
.multi-select__option--deep-purple > .multi-select__option-remove-button:hover {
  background-color: #6d229b;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--deep-purple > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #fff;
}
.multi-select__option--deep-purple
  > .multi-select__option-name--is-clickable:hover {
  background-color: #6d229b;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--deep-purple:hover {
  color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--indigo {
  color: #fff;
  background-color: #3655ba;
}
.multi-select__option--indigo > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #fff;
}
.multi-select__option--indigo > .multi-select__option-remove-button:hover {
  background-color: #2b4392;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--indigo > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #fff;
}
.multi-select__option--indigo > .multi-select__option-name--is-clickable:hover {
  background-color: #2b4392;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--indigo:hover {
  color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--blue {
  color: #151515;
  background-color: #abbaed;
}
.multi-select__option--blue > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--blue > .multi-select__option-remove-button:hover {
  background-color: #8198e4;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--blue > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--blue > .multi-select__option-name--is-clickable:hover {
  background-color: #8198e4;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--blue:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--cyan {
  color: #151515;
  background-color: #74d1ff;
}
.multi-select__option--cyan > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--cyan > .multi-select__option-remove-button:hover {
  background-color: #41c0ff;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--cyan > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--cyan > .multi-select__option-name--is-clickable:hover {
  background-color: #41c0ff;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--cyan:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--teal {
  color: #151515;
  background-color: #00e0b1;
}
.multi-select__option--teal > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--teal > .multi-select__option-remove-button:hover {
  background-color: #00ad89;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--teal > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--teal > .multi-select__option-name--is-clickable:hover {
  background-color: #00ad89;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--teal:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--green {
  color: #fff;
  background-color: #007f63;
}
.multi-select__option--green > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #fff;
}
.multi-select__option--green > .multi-select__option-remove-button:hover {
  background-color: #004c3b;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--green > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #fff;
}
.multi-select__option--green > .multi-select__option-name--is-clickable:hover {
  background-color: #004c3b;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--green:hover {
  color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--green-inverted {
  color: #151515;
  background-color: #00bf96;
}
.multi-select__option--green-inverted > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--green-inverted
  > .multi-select__option-remove-button:hover {
  background-color: #008c6e;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--green-inverted
  > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--green-inverted
  > .multi-select__option-name--is-clickable:hover {
  background-color: #008c6e;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--green-inverted:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--light-green {
  color: #fff;
  background-color: #4b7c00;
}
.multi-select__option--light-green > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #fff;
}
.multi-select__option--light-green > .multi-select__option-remove-button:hover {
  background-color: #2c4900;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--light-green > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #fff;
}
.multi-select__option--light-green
  > .multi-select__option-name--is-clickable:hover {
  background-color: #2c4900;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--light-green:hover {
  color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--lime {
  color: #151515;
  background-color: #89dc00;
}
.multi-select__option--lime > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--lime > .multi-select__option-remove-button:hover {
  background-color: #69a900;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--lime > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--lime > .multi-select__option-name--is-clickable:hover {
  background-color: #69a900;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--lime:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--yellow {
  color: #151515;
  background-color: #ffb784;
}
.multi-select__option--yellow > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--yellow > .multi-select__option-remove-button:hover {
  background-color: #ff9951;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--yellow > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--yellow > .multi-select__option-name--is-clickable:hover {
  background-color: #ff9951;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--yellow:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--amber {
  color: #151515;
  background-color: #fe9a9a;
}
.multi-select__option--amber > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--amber > .multi-select__option-remove-button:hover {
  background-color: #fe6868;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--amber > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--amber > .multi-select__option-name--is-clickable:hover {
  background-color: #fe6868;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--amber:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--orange {
  color: #fff;
  background-color: #c64300;
}
.multi-select__option--orange > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #fff;
}
.multi-select__option--orange > .multi-select__option-remove-button:hover {
  background-color: #933200;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--orange > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #fff;
}
.multi-select__option--orange > .multi-select__option-name--is-clickable:hover {
  background-color: #933200;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--orange:hover {
  color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--brown {
  color: #fff;
  background-color: #a35f00;
}
.multi-select__option--brown > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #fff;
}
.multi-select__option--brown > .multi-select__option-remove-button:hover {
  background-color: #704100;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--brown > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #fff;
}
.multi-select__option--brown > .multi-select__option-name--is-clickable:hover {
  background-color: #704100;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--brown:hover {
  color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--grey {
  color: #fff;
  background-color: #69707c;
}
.multi-select__option--grey > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #fff;
}
.multi-select__option--grey > .multi-select__option-remove-button:hover {
  background-color: #525760;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--grey > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #fff;
}
.multi-select__option--grey > .multi-select__option-name--is-clickable:hover {
  background-color: #525760;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--grey:hover {
  color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--dark-blue {
  color: #fff;
  background-color: #3655ba;
}
.multi-select__option--dark-blue > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #fff;
}
.multi-select__option--dark-blue > .multi-select__option-remove-button:hover {
  background-color: #2b4392;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--dark-blue > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #fff;
}
.multi-select__option--dark-blue
  > .multi-select__option-name--is-clickable:hover {
  background-color: #2b4392;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--dark-blue:hover {
  color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--dark-blue-inverted {
  color: #151515;
  background-color: #7d95e3;
}
.multi-select__option--dark-blue-inverted
  > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--dark-blue-inverted
  > .multi-select__option-remove-button:hover {
  background-color: #5373da;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--dark-blue-inverted
  > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--dark-blue-inverted
  > .multi-select__option-name--is-clickable:hover {
  background-color: #5373da;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--dark-blue-inverted:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--cool-grey {
  color: #151515;
  background-color: #c2c7cf;
}
.multi-select__option--cool-grey > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--cool-grey > .multi-select__option-remove-button:hover {
  background-color: #a5adb9;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--cool-grey > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--cool-grey
  > .multi-select__option-name--is-clickable:hover {
  background-color: #a5adb9;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--cool-grey:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--light-grey {
  color: #151515;
  background-color: #dde0e4;
}
.multi-select__option--light-grey > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--light-grey > .multi-select__option-remove-button:hover {
  background-color: #c1c6cd;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--light-grey > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--light-grey
  > .multi-select__option-name--is-clickable:hover {
  background-color: #c1c6cd;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--light-grey:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--product {
  color: #fff;
  background-color: #db3c00;
}
.multi-select__option--product > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #fff;
}
.multi-select__option--product > .multi-select__option-remove-button:hover {
  background-color: #a82e00;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--product > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #fff;
}
.multi-select__option--product
  > .multi-select__option-name--is-clickable:hover {
  background-color: #a82e00;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--product:hover {
  color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--product-disabled {
  color: #151515;
  background-color: #ffb4a9;
}
.multi-select__option--product-disabled > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--product-disabled
  > .multi-select__option-remove-button:hover {
  background-color: #ff8876;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--product-disabled
  > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--product-disabled
  > .multi-select__option-name--is-clickable:hover {
  background-color: #ff8876;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--product-disabled:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option--warning {
  color: #151515;
  background-color: #f49100;
}
.multi-select__option--warning > .multi-select__option-remove-button {
  padding: 8px 12px 8px 8px;
  color: #151515;
}
.multi-select__option--warning > .multi-select__option-remove-button:hover {
  background-color: #c17300;
  border-top-right-radius: 5000px;
  border-bottom-right-radius: 5000px;
}
.multi-select__option--warning > .multi-select__option-name--is-clickable {
  padding: 8px 12px 8px 16px;
  color: #151515;
}
.multi-select__option--warning
  > .multi-select__option-name--is-clickable:hover {
  background-color: #c17300;
  border-bottom-left-radius: 5000px;
  border-top-left-radius: 5000px;
}
.multi-select__option--warning:hover {
  color: #151515;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.multi-select__option-name {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 8px 12px 8px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  vertical-align: middle;
  cursor: default;
}
.multi-select__option-name:last-child {
  padding-right: 16px;
}
.multi-select__option-name--is-clickable {
  cursor: pointer;
}
.multi-select__option-remove-button {
  display: inline-flex;
  align-items: center;
  padding: 4px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 0 5000px 5000px 0;
}
.multi-select__option-area {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 0;
  padding-bottom: 2px;
  resize: vertical;
}
.multi-select__option-area:last-child {
  padding-right: 32px;
}
.content-item-element__content .multi-select__option-area {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.multi-select__drop-down-actions {
  align-self: start;
}
.multi-select__drop-down-expand {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 17px 0 8px;
  margin: -1px -1px -1px 0;
  cursor: pointer;
}
.multi-select--is-disabled .multi-select__drop-down-expand {
  cursor: not-allowed;
}
.multi-select__drop-down-expand-icon {
  font-size: 16px;
  color: #000;
}
.multi-select--is-disabled .multi-select__drop-down-expand-icon {
  color: #8c8c8c;
}
.checkbox {
  position: relative;
}
.checkbox__symbol {
  display: flex;
  padding: 4px;
  cursor: pointer;
}
.checkbox--is-disabled .checkbox__symbol {
  cursor: not-allowed;
}
.checkbox__symbol:before {
  font-family: kentico-icons;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  line-height: 16px;
  border-radius: 4px;
  content: "";
  box-sizing: border-box;
}
.checkbox__input[checked] + .checkbox__symbol:before {
  content: "\e6b0";
}
.checkbox--alternative-colors .checkbox__symbol:before,
.checkbox--default-colors .checkbox__symbol:before {
  background-color: #fff;
  border: 1px solid #a8a8a8;
}
.checkbox--alternative-colors .checkbox__input:focus + .checkbox__symbol:before,
.checkbox--default-colors .checkbox__input:focus + .checkbox__symbol:before {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible
  .checkbox--alternative-colors
  .checkbox__input:focus
  + .checkbox__symbol:before:focus:not(.focus-visible),
.js-focus-visible
  .checkbox--default-colors
  .checkbox__input:focus
  + .checkbox__symbol:before:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.js-focus-visible
  .checkbox--alternative-colors
  .checkbox__input:focus:not(.focus-visible)
  + .checkbox__symbol:before,
.js-focus-visible
  .checkbox--default-colors
  .checkbox__input:focus:not(.focus-visible)
  + .checkbox__symbol:before {
  box-shadow: none;
}
.checkbox--alternative-colors:hover .checkbox__symbol:before,
.checkbox--default-colors:hover .checkbox__symbol:before {
  background-color: #fff0ef;
  border: 1px solid #953000;
}
.checkbox--alternative-colors
  .checkbox__input[checked]
  + .checkbox__symbol:before,
.checkbox--default-colors .checkbox__input[checked] + .checkbox__symbol:before {
  padding: 1px 0 0 1px;
  color: #fff;
  background-color: #db3c00;
  border: none;
}
.checkbox--alternative-colors:hover
  .checkbox__input[checked]
  + .checkbox__symbol:before,
.checkbox--default-colors:hover
  .checkbox__input[checked]
  + .checkbox__symbol:before {
  padding: 1px 0 0 1px;
  color: #fff;
  background-color: #953000;
  border: none;
}
.checkbox--alternative-colors
  .checkbox__input:focus[checked][disabled]
  + .checkbox__symbol:before,
.checkbox--alternative-colors
  .checkbox__input[checked][disabled]
  + .checkbox__symbol:before,
.checkbox--alternative-colors:hover
  .checkbox__input[checked][disabled]
  + .checkbox__symbol:before,
.checkbox--default-colors
  .checkbox__input:focus[checked][disabled]
  + .checkbox__symbol:before,
.checkbox--default-colors
  .checkbox__input[checked][disabled]
  + .checkbox__symbol:before,
.checkbox--default-colors:hover
  .checkbox__input[checked][disabled]
  + .checkbox__symbol:before {
  padding: 0;
  color: #8c8c8c;
  background-color: #dfdfdf;
  border: 1px solid #a8a8a8;
}
.checkbox--alternative-colors
  .checkbox__input:focus[disabled]
  + .checkbox__symbol:before,
.checkbox--alternative-colors
  .checkbox__input[disabled]
  + .checkbox__symbol:before,
.checkbox--alternative-colors:hover
  .checkbox__input[disabled]
  + .checkbox__symbol:before,
.checkbox--default-colors
  .checkbox__input:focus[disabled]
  + .checkbox__symbol:before,
.checkbox--default-colors .checkbox__input[disabled] + .checkbox__symbol:before,
.checkbox--default-colors:hover
  .checkbox__input[disabled]
  + .checkbox__symbol:before {
  background-color: #dfdfdf;
  border: 1px solid #a8a8a8;
}
.option__list--item-element {
  padding-left: 1px;
}
.card__content .option__list {
  margin-top: -8px;
}
.option__list + .option__list {
  margin-top: 8px;
}
.option__pane {
  position: relative;
  display: block;
}
.option__pane + .option__pane {
  margin-top: 4px;
}
.option__input--hidden {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.option {
  display: inline-flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
}
.option:before {
  font-family: kentico-icons;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
  margin-right: 4px;
  line-height: 16px;
}
.option:not(.option--is-disabled):hover {
  white-space: normal;
  background: #fff0ef;
}
.toggle__input--hidden {
  left: -9999px;
  opacity: 0;
  position: absolute;
}
.toggle__input--hidden:focus + .option {
  white-space: normal;
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible
  .toggle__input--hidden:focus
  + .option:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.js-focus-visible .toggle__input--hidden:focus:not(.focus-visible) + .option {
  box-shadow: none;
}
.option--is-checkbox:before {
  font-family: kentico-icons;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  background-color: #fff;
  border: 1px solid #a8a8a8;
  border-radius: 4px;
  content: "";
  box-sizing: border-box;
}
.option--is-checkbox.option--is-selected:before {
  padding: 1px 0 0 1px;
  color: #fff;
  background-color: #db3c00;
  border: none;
  content: "\e6b0";
}
.option--is-checkbox.option--is-disabled:before {
  background-color: #dfdfdf;
  border: 1px solid #a8a8a8;
}
.option--is-checkbox.option--is-selected.option--is-disabled:before {
  padding: 0;
  color: #8c8c8c;
}
.option--is-radio-button:before {
  color: #a8a8a8;
  background-color: #fff;
  border-radius: 5000px;
  content: "\e626";
}
.option--is-radio-button.option--is-selected:before {
  color: #db3c00;
  content: "\e907";
}
.option--is-radio-button.option--is-disabled:before {
  color: #8c8c8c;
  background-color: #dfdfdf;
}
.option--is-fullsize {
  width: 100%;
}
.option--is-disabled {
  color: #8c8c8c;
  cursor: not-allowed;
}
.option--content-type:before {
  margin-left: 0;
}
.option__label {
  min-width: 0;
  flex: 1 1 auto;
  color: #151515;
  word-break: break-word;
  -webkit-user-select: none;
  user-select: none;
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.option__label--with-dropdown {
  width: auto;
  flex: none;
  min-width: 25%;
}
.option__description {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  padding-left: 28px;
  margin-top: 4px;
  margin-bottom: 16px;
  color: #6f6f6f;
}
.option__description-list {
  margin-top: 0;
}
.switch-wrapper {
  display: flex;
  align-items: center;
}
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  background: #525252;
  border-radius: 5000px;
}
.switch,
.switch:before {
  transition: 0.25s cubic-bezier(0.23, 1, 0.32, 1) 50ms;
}
.switch:before {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
  content: "";
}
.switch:focus {
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible .switch:focus:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.switch:hover {
  background: #525252;
}
.switch:hover:before {
  background: #dfdfdf;
}
input:checked + .switch {
  background: #db3c00;
}
input:checked + .switch:before {
  top: 2px;
  left: 18px;
  width: 16px;
  height: 16px;
}
input:checked + .switch:hover {
  background: #953000;
}
input:checked + .switch:hover:before {
  background: #dfdfdf;
}
input:disabled + .switch {
  cursor: not-allowed;
  background: #dfdfdf;
}
input:disabled + .switch:before {
  background: #6f6f6f;
}
input:checked:disabled + .switch {
  cursor: not-allowed;
  background: #ffb4a9;
}
input:checked:disabled + .switch:before {
  background: #fff;
}
.switch__label {
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 8px;
  color: #151515;
  white-space: nowrap;
}
.text-field {
  position: relative;
  display: flex;
  width: 100%;
  background-color: initial;
}
.text-field--is-growing {
  display: inline-flex;
  width: auto;
  max-width: 100%;
}
.text-field__input {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  width: 100%;
  height: 40px;
  min-width: 0;
  padding: 9px 16px;
  color: #151515;
  background-color: initial;
  border: 1px solid #a8a8a8;
  border-radius: 20px;
  transition: border 0.25s cubic-bezier(0.23, 1, 0.32, 1) 50ms;
  resize: vertical;
}
.text-field__input--is-multiline {
  min-height: 40px;
  resize: none;
}
.text-field__input::placeholder {
  color: #6f6f6f;
  -webkit-text-fill-color: #6f6f6f;
  opacity: 1;
}
.text-field--has-focus .text-field__input,
.text-field__input:focus {
  color: #151515;
  border-color: #141619;
  outline: none;
}
.text-field--is-disabled .text-field__input,
.text-field__input:disabled {
  color: #8c8c8c;
  cursor: not-allowed;
  background-color: #dfdfdf;
  border-color: #c6c6c6;
  opacity: 1;
}
.text-field--is-disabled.text-field--is-disabled-in-content-editor
  .text-field__input,
.text-field__input:disabled {
  color: #151515;
}
.text-field__input[readonly] {
  color: #151515;
  text-overflow: ellipsis;
  cursor: text;
  background-color: #dfdfdf;
  border-color: #c6c6c6;
}
.text-field__input[readonly]:focus {
  border-color: #c6c6c6;
}
.text-field--has-error .text-field__input,
.text-field--has-error .text-field__input:focus {
  color: #db0000;
  border: 1px solid #b10202;
}
.text-field--has-error .text-field__input:focus,
.text-field--has-error .text-field__input:focus:focus,
.text-field--has-error .text-field__input:focus:hover,
.text-field--has-error .text-field__input:hover {
  border-color: #b10202;
}
.content-item-element__datetime .text-field--has-error .text-field__input,
.content-item-element__datetime
  .text-field--has-error
  .text-field__input:focus {
  border: none;
}
.text-field--has-button .text-field__input {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 52px;
}
.text-field__button-pane {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
}
.text-field__button-icon {
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: space-around;
  color: #151515;
  cursor: pointer;
  background: transparent;
  border-radius: 4px;
}
.text-field__button-icon:hover {
  color: #151515;
  background-color: rgba(21, 21, 21, 0.1);
}
.text-field__button-icon:active,
.text-field__input:focus + .text-field__button-pane .text-field__button-icon {
  color: #db3c00;
  background-color: #fff0ef;
}
.text-field__input:focus
  + .text-field__button-pane
  .text-field__button-icon:hover {
  background-color: rgba(21, 21, 21, 0.1);
}
.text-field--has-error
  .text-field__input:focus
  + .text-field__button-pane
  .text-field__button-icon {
  box-shadow: none;
}
.text-field--is-disabled
  .text-field__input
  + .text-field__button-pane
  .text-field__button-icon,
.text-field__button-icon--is-disabled {
  color: #8c8c8c;
  cursor: not-allowed;
  background-color: initial;
}
.content-component__element .text-field__input {
  flex: auto;
  max-width: unset;
  margin-right: -1px;
}
.content-component__element
  .text-field.text-field--has-button
  .text-field__input {
  margin-right: 0;
}
.tree {
  width: 100%;
  overflow: hidden;
}
.tree__list {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
}
.tree__list .tree__list {
  padding-left: 24px;
}
.tree__list--is-collapsed {
  display: none;
  max-height: 0;
  visibility: hidden;
}
.tree__list--is-expanded {
  visibility: visible;
}
.tree__node + .tree__node,
.tree__node .tree__node {
  margin-top: 4px;
}
.tree__item {
  display: flex;
  align-items: center;
}
.tree__expand-area {
  margin: 4px 4px 4px 0;
  -webkit-user-select: none;
  user-select: none;
}
.tree__expand-action {
  font-family: kentico-icons;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
  display: flex;
  padding: 4px;
  color: #000;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.tree__expand-action:hover {
  background-color: rgba(21, 21, 21, 0.1);
}
.tree__expand-action:active {
  color: #db3c00;
  background-color: #fff0ef;
}
.tree__expand-action--is-collapsed {
  transform: rotate(-90deg);
}
.tree__expand-action--is-disabled,
.tree__expand-action--is-disabled:hover {
  cursor: default;
  visibility: hidden;
}
.tree__name {
  display: inline-flex;
  padding: 4px;
  color: #151515;
  word-break: break-all;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -webkit-user-select: none;
  user-select: none;
  font-family: GT Walsheim Pro, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.tree__name:before {
  margin-right: 4px;
  font-size: 16px;
}
.tree__name--is-selectable:before,
.tree__name:before {
  font-family: kentico-icons;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  speak: none;
}
.tree__name--is-selectable:before {
  font-size: 15px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  padding-left: 8px;
  color: #a8a8a8;
  background-color: #fff;
  border: 1px solid #a8a8a8;
  border-radius: 4px;
  content: "";
  box-sizing: border-box;
}
.tree__name--is-selected {
  color: #151515;
}
.tree__name--is-selected:before {
  padding: 1px 0 0 1px;
  color: #fff;
  background-color: #db3c00;
  border: none;
  content: "\e6b0";
}
.tree__name:not(.tree__name--is-disabled):hover {
  color: #151515;
  background-color: #fff0ef;
}
.toggle__input--hidden:focus + .tree__name {
  color: #151515;
  box-shadow: 0 0 0 2px #0093ff, inset 0 0 3px 0 #0093ff, 0 0;
}
.js-focus-visible
  .toggle__input--hidden:focus
  + .tree__name:focus:not(.focus-visible) {
  box-shadow: 0 0;
}
.js-focus-visible
  .toggle__input--hidden:focus:not(.focus-visible)
  + .tree__name {
  box-shadow: none;
}
.tree__name--is-disabled {
  cursor: not-allowed;
}
.tree__name--is-disabled:before {
  background-color: #dfdfdf;
  border: 1px solid #a8a8a8;
}
.tree__name--is-disabled.tree__name--is-selected:before {
  padding: 0;
  color: #8c8c8c;
}
.sidebar__section .tree__node + .tree__node,
.sidebar__section .tree__node .tree__node {
  margin-top: 0;
}
.sidebar__section .tree__item {
  min-height: 32px;
}
