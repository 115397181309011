.preview-modal-parent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgb(0 0 0 / 16%);
  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    height: 100%;
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
  }

  .modal {
    position: relative;
    background: white;
    padding: 1.5rem;
    width: calc(100% - 2rem);
    max-width: 600px;
    height: auto;
    max-height: 80%;
    overflow: scroll;
  }

  .title-area {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(198, 198, 198);
  }

  .body-small {
    margin-bottom: 1rem;
  }

  .preview-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    .column {
      .body-regular {
        color: rgb(140, 140, 140);
        margin-bottom: 0.25rem;
      }
    }
  }
}
