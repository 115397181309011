.multi-select-parent {
  margin-bottom: 1.5rem;

  .multi-select {
    cursor: pointer;
  }

  .multi-select__drop-down-expand,
  .multi-select__option-remove-button {
    outline: none;
    background: transparent;
    border: none;
  }

  .body-medium-emphasis {
    margin-bottom: 0.5rem;
  }

  .multi-select__dropdown {
    position: absolute;
    z-index: 2;
    margin-top: 4px;
    margin-left: 0.5rem;
  }

  .multi-select__option-wrapper {
    display: flex;
  }

  .multi-select__option-area {
    overflow: scroll;
  }

  .multi-select__dropdown-option {
    &:hover {
      background-color: rgba(21, 21, 21, 0.1);
    }

    &[data-active] {
      background-color: #ffd6d1;
    }
  }

  .close {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
  }
}
