.copy-parent {
  width: 100%;
  max-width: 500px;

  .body-large {
    margin-bottom: 1rem;
  }

  .checkbox-label {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.5rem;
    }
  }

  .language-list {
    margin-bottom: 1rem;
  }

  .status {
    margin-bottom: 1rem;
  }
}
